import React from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { useDateTime } from "../../components/Helper/DateTime";
import NewFilter from "../../components/FilterDropdown/NewFilter";
const GenerateReportModal = ({
  showModal,
  setShowModal,
  generateReport,
  setDateFrom,
  setDateTo,
  dateFrom,
  dateTo,
  setReportsListLoader,
  setPage,
}) => {
  const { date } = useDateTime();
  return (
    <div className="col-md-12">
      <Modal
        backdrop={true}
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <div className="color-dark">Generate Report</div>
        </Modal.Header>
        <Modal.Body scrollable="true">
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <label>Date From</label>
              <NewFilter
                title={
                  dateFrom !== ""
                    ? date && moment(dateFrom).format(date)
                    : "Select Date"
                }
                type="date"
                date={dateFrom ? new Date(dateFrom) : null}
                onChange={(e) => setDateFrom(e)}
              />
            </div>
            <div className="col-sm-12 col-lg-6">
              <label>Date To</label>
              <NewFilter
                title={
                  dateTo !== ""
                    ? date && moment(dateTo).format(date)
                    : "Select Date"
                }
                type="date"
                date={dateTo ? new Date(dateTo) : null}
                onChange={(e) => setDateTo(e)}
              />
              {/* </div> */}
            </div>
          </div>
          <div className="row mt-4">
            <div className="offset-sm-9 col-sm-3 text-right">
              <button
                className="btn btn-info mr-2"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>{" "}
              <button
                className="btn btn-info"
                onClick={() => {
                  setReportsListLoader(true);
                  setPage(1);
                  generateReport();
                  setShowModal(false);
                }}
              >
                Done
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default GenerateReportModal;
