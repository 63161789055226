import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLocation, useHistory, Link } from "react-router-dom";
import * as Yup from "yup";
import { withRouter } from "react-router";
import logo from "../../assets/images/logo-sm.svg";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import axiosInstance from "../../utils/axiosInstance";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import Toast from "../../components/Toast/Toast";
import { useDateTime } from "../../components/Helper/DateTime";

const twoFactorAuthSchema = Yup.object().shape({
  code: Yup.string().required("Code is required"),
});

const VerifyTwoFactorAuth = () => {
  const { setDate, setTime, setDateTime } = useDateTime;
  const location = useLocation();
  const history = useHistory();
  const [authData, setAuthData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setIsLoading(true);
    if (location.state && location.state.twoFactorAuthData) {
      setAuthData(location.state.twoFactorAuthData);
    }
    setIsLoading(false);
  }, []);

  const handle2fa = async (values) => {
    setIsLoading(true);
    let payload = {
      data: {
        verifyTwoFactorAuthToken: authData?.verifyTwoFactorAuthToken,
        otp: values?.code,
      },
    };
    await axiosInstance
      .post(`/users/verify-totp`, payload)
      .then((res) => {
        if (res.message.status !== "200") {
          setIsLoading(false);
          setErrorMessage(res.message.description);
        } else {
          const jsonResponse = res;
          localStorage.setItem("userData", JSON.stringify(jsonResponse?.data));
          if (jsonResponse?.message?.status === "200") {
            axiosInstance
              .get(`/roles/users`)
              .then((response) => {
                if (response?.message?.status !== "200") {
                  Toast(response?.message?.description, "error");
                } else {
                  localStorage.setItem("userRoles", JSON.stringify(response));
                  history.push("/");
                }
              })
              .catch((error) => {
                console.log("user info error", error);
              });
          }
          const { userPreferences } = jsonResponse?.data;
          if (userPreferences?.length > 0) {
            userPreferences?.forEach((pref) => {
              if (pref.prefKeys === "dateFormat") {
                setDate(pref.prefValues);
              } else if (pref.prefKeys === "timeFormat") {
                setTime(pref.prefValues);
              } else {
                setDateTime(pref.prefValues);
              }
            });
            setIsLoading(false);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage(error?.response?.data?.message?.description);
      });
  };

  return (
    <>
      {isLoading && (
        <div
          className="spinner-center"
          style={{ opacity: 1, top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}

      <div
        className="auth-container"
        style={{ opacity: !isLoading ? "1" : "0.07" }}
      >
        <div className="d-flex align-items-center brand-name-wrapper">
          <img
            src={logo}
            alt="Logo"
            width="50"
            style={{ margin: "20px", cursor: "pointer" }}
          />
        </div>
        <div>
          <div className="d-flex align-items-center justify-content-center mt-5">
            <h4 className="font-weight-bold">
              Verify Two Factor Authentication
            </h4>
          </div>
          <div className="card auth-card mt-2" style={{ width: "60%" }}>
            <div className="card-body">
              {location?.state && location?.state?.twoFactorAuthData ? (
                <div className="row pt-3">
                  <div className="col-3"></div>
                  <div className="col-6">
                    <>
                      <div className="d-flex align-items-center">
                        <p className="font-weight-bold">
                          {" "}
                          1. Enter the code provided by your authenticator app{" "}
                        </p>
                      </div>
                      <div className="container mt-2">
                        <div
                          id="login-row"
                          className="row justify-content-center align-items-center"
                        >
                          <div id="login-column" className="col-md-12">
                            <div id="login-box" className="col-md-12">
                              <Formik
                                initialValues={{ code: "" }}
                                validationSchema={twoFactorAuthSchema}
                                onSubmit={(values) => {
                                  handle2fa(values);
                                }}
                              >
                                {({ handleSubmit, values }) => (
                                  <Form id="login-form" className="form">
                                    <div className="form-group">
                                      <label
                                        htmlFor="code"
                                        className="text-info"
                                      >
                                        Authentication Code:
                                      </label>
                                      <br />
                                      <Field
                                        type="text"
                                        name="code"
                                        id="code"
                                        className="form-control rs-input"
                                        placeholder="Enter Code"
                                        style={{ borderRadius: "10px" }}
                                      />
                                      <ErrorMessage
                                        name="code"
                                        component="div"
                                        className="text-danger"
                                      />
                                      {values.code && errorMessage ? (
                                        <div className="text-danger">
                                          {errorMessage}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <ButtonBasic
                                        id="submit"
                                        type="submit"
                                        title="Verify"
                                        className="btn btn-info btn-md"
                                        style={{ width: "100%" }}
                                      />
                                    </div>
                                  </Form>
                                )}
                              </Formik>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                  <div className="col-3"></div>
                </div>
              ) : (
                <div className="row pt-3">
                  <div className="col-12">
                    <div className="d-flex align-items-center justify-content-center">
                      <p className="font-weight-bold text-danger">
                        {" "}
                        You can't access this page directly.You have to follow
                        proper flow to access this page and you can't access
                        this page without 2FA Enabled.
                      </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-center mt-3">
                      <Link to="/login" id="register-link">
                        <p className="text-center text-info text-underline">
                          Back To Login
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(VerifyTwoFactorAuth);
