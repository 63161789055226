import React, { useState } from "react";
import { ErrorMessage, Field, FieldArray } from "formik";
import { Collapse, FormCheck, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select, { components } from "react-select";
import AddIcon from "../../../assets/images/add-icon.png";
import ButtonTransparent from "../../../components/Buttons/ButtonTransparent";
import OperatorDescription from "./OperatorDescription.json";
import { errorStyling } from "./ValidationSchema";

const PairExMatchingRule = ({
  values,
  dsaFields,
  dsbFields,
  operatorOptions,
  setFieldValue,
  errors,
}) => {
  const [open, setOpen] = useState([]);
  const isValidJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  };
  return (
    <div>
      {" "}
      <FieldArray name={`matchingRule.fields`}>
        {({ push }) => (
          <>
            <div className="mt-4 d-flex flex-wrap align-items-center">
              <div className="col-md-12">
                <div className="float-right">
                  <ButtonTransparent
                    title={"Add Field"}
                    icon={AddIcon}
                    onClick={() =>
                      push({
                        ds_a_field_name: "",
                        ds_b_field_name: "",
                        operator: "",
                        label: "",
                      })
                    }
                    border
                  />
                </div>
              </div>
            </div>
            {values?.matchingRule &&
              isValidJSON(JSON.stringify(values?.matchingRule)) &&
              values?.matchingRule?.fields?.map((item, index1) => (
                <div className="border p-3 my-3">
                  <div className="row">
                    <div className="col-3">
                      {/* DropDown */}
                      <div className="workflowModalTitle">DSA Field Name</div>
                      <div title={item?.ds_a_field_name}>
                        <Select
                          options={dsaFields}
                          name={"ds A Field Name"}
                          value={dsaFields?.find(
                            (field) => field.value === item?.ds_a_field_name
                          )}
                          onChange={(e) =>
                            setFieldValue(
                              `matchingRule.fields[${index1}].ds_a_field_name`,
                              e?.value
                            )
                          }
                          isClearable
                          menuPosition="fixed" // Make menu fixed
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                          }}
                        />
                      </div>
                      <ErrorMessage
                        component={() => (
                          <div style={errorStyling}>
                            {
                              errors?.matchingRule?.fields?.[index1]
                                ?.ds_a_field_name
                            }
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-3">
                      <div className="workflowModalTitle">Operator</div>
                      <div
                        title={
                          operatorOptions?.matchingRuleOperators?.find(
                            (operator) =>
                              operator.value === item?.operator?.split("(")[0]
                          )?.label
                        }
                      >
                        <Select
                          options={operatorOptions?.matchingRuleOperators}
                          name={"Operator"}
                          value={operatorOptions?.matchingRuleOperators?.find(
                            (operator) =>
                              operator.value === item?.operator?.split("(")[0]
                          )}
                          onChange={(e) => {
                            const newValue = e?.value;
                            if (newValue?.includes("FUZZY")) {
                              setFieldValue(
                                `matchingRule.fields[${index1}].operator`,
                                `${newValue}(0)`
                              );
                            } else if (newValue?.split("(")[0] === "IN") {
                              setFieldValue(
                                `matchingRule.fields[${index1}].operator`,
                                `IN(ds_a_field_name,ds_b_field_name)`
                              );
                            } else if (newValue?.split("(")[0] === "NIN") {
                              setFieldValue(
                                `matchingRule.fields[${index1}].operator`,
                                `NIN(ds_a_field_name,ds_b_field_name)`
                              );
                            } else if (
                              newValue?.split("(")[0] === "REGEX-MATCH"
                            ) {
                              setFieldValue(`matchingRule.fields[${index1}]`, {
                                ...values.matchingRule.fields[index1],
                                operator: newValue,
                                regex: "",
                              });
                            } else {
                              setFieldValue(
                                `matchingRule.fields[${index1}].operator`,
                                newValue
                              );
                            }
                          }}
                          components={{
                            Option: (props) =>
                              OperatorDescription?.find(
                                (op) => op?.operator == props?.data?.value
                              )?.description ? (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip">
                                      {
                                        OperatorDescription?.find(
                                          (op) =>
                                            op?.operator == props?.data?.value
                                        )?.description
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <div>
                                    <components.Option {...props} />
                                  </div>
                                </OverlayTrigger>
                              ) : (
                                <components.Option {...props} />
                              ),
                          }}
                          isClearable
                        />
                      </div>
                      <ErrorMessage
                        component={() => (
                          <div style={errorStyling}>
                            {errors?.matchingRule?.fields?.[index1]?.operator}
                          </div>
                        )}
                      />
                    </div>

                    {item?.operator?.includes("FUZZY") && (
                      <div className="col-2">
                        <div className="workflowModalTitle">Matching %</div>
                        <Field
                          title={
                            item?.operator?.match(/\(([^)]+)\)/)?.length > 0
                              ? item?.operator?.match(/\(([^)]+)\)/)[1]
                              : ""
                          }
                          className="form-control rs-input highlight"
                          type="number"
                          placeholder="Enter Matching %"
                          name="matching_percentage"
                          min="0"
                          max="100"
                          value={
                            item?.operator?.match(/\(([^)]+)\)/)?.length > 0
                              ? item?.operator?.match(/\(([^)]+)\)/)[1]
                              : ""
                          }
                          onChange={(e) => {
                            const newPercentage = e.target.value;
                            const updatedOperator = item.operator.replace(
                              /\(.*?\)/,
                              `(${newPercentage})`
                            );
                            setFieldValue(
                              `matchingRule.fields[${index1}].operator`,
                              updatedOperator
                            );
                          }}
                        />
                      </div>
                    )}

                    {item?.operator?.split("(")[0] === "REGEX-MATCH" && (
                      <div className="col-2">
                        <div className="workflowModalTitle">Regex</div>
                        <Field
                          title={item?.regex}
                          className="form-control rs-input highlight"
                          placeholder="Regex"
                          name={`matchingRule.fields[${index1}].regex`}
                          onChange={(e) => {
                            setFieldValue(
                              `matchingRule.fields[${index1}].regex`,
                              e.target.value
                            );
                          }}
                          value={item?.regex}
                        />
                        <ErrorMessage
                          component={() => (
                            <div style={errorStyling}>
                              {errors?.matchingRule?.fields?.[index1]?.regex}
                            </div>
                          )}
                        />
                      </div>
                    )}

                    {item?.operator?.split("(")[0] === "IN" && (
                      <div
                        className="col-2 d-flex"
                        style={{ paddingTop: "2rem" }}
                      >
                        <span className="mr-2 cursor-context-menu">
                          {"A->B"}
                        </span>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch1"
                            checked={
                              item?.operator
                                ? item?.operator
                                    .match(/IN\(([^,]+),([^,]+)\)/)[1]
                                    .trim() === "ds_a_field_name" &&
                                  item?.operator
                                    .match(/IN\(([^,]+),([^,]+)\)/)[2]
                                    .trim() === "ds_b_field_name"
                                  ? false
                                  : true
                                : false
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFieldValue(
                                  `matchingRule.fields[${index1}].operator`,
                                  "IN(ds_b_field_name,ds_a_field_name)"
                                );
                              } else {
                                setFieldValue(
                                  `matchingRule.fields[${index1}].operator`,
                                  "IN(ds_a_field_name,ds_b_field_name)"
                                );
                              }
                            }}
                          />
                          <label
                            className="custom-control-label"
                            for="customSwitch1"
                          ></label>
                        </div>
                        <span class="ml-2 cursor-context-menu">{"B->A"}</span>
                      </div>
                    )}
                    {item?.operator?.split("(")[0] === "NIN" && (
                      <div
                        className="col-2 d-flex"
                        style={{ paddingTop: "2rem" }}
                      >
                        <span className="mr-2 cursor-context-menu">
                          {"A->B"}
                        </span>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch1"
                            checked={
                              item?.operator
                                ? item?.operator
                                    .match(/NIN\(([^,]+),([^,]+)\)/)[1]
                                    .trim() === "ds_a_field_name" &&
                                  item?.operator
                                    .match(/NIN\(([^,]+),([^,]+)\)/)[2]
                                    .trim() === "ds_b_field_name"
                                  ? false
                                  : true
                                : false
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFieldValue(
                                  `matchingRule.fields[${index1}].operator`,
                                  "NIN(ds_b_field_name,ds_a_field_name)"
                                );
                              } else {
                                setFieldValue(
                                  `matchingRule.fields[${index1}].operator`,
                                  "NIN(ds_a_field_name,ds_b_field_name)"
                                );
                              }
                            }}
                          />
                          <label
                            className="custom-control-label"
                            for="customSwitch1"
                          ></label>
                        </div>
                        <span class="ml-2 cursor-context-menu">{"B->A"}</span>
                      </div>
                    )}

                    <div className="col-3">
                      {/* DropDown */}
                      <div className="workflowModalTitle">DSB Field Name</div>
                      <div title={item?.ds_b_field_name}>
                        <Select
                          options={dsbFields}
                          name={"ds B Field Name"}
                          value={dsbFields?.find(
                            (field) => field.value === item?.ds_b_field_name
                          )}
                          onChange={(e) =>
                            setFieldValue(
                              `matchingRule.fields[${index1}].ds_b_field_name`,
                              e?.value
                            )
                          }
                          isClearable
                          menuPosition="fixed" // Make menu fixed
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                          }}
                        />
                      </div>
                      <ErrorMessage
                        component={() => (
                          <div style={errorStyling}>
                            {
                              errors?.matchingRule?.fields?.[index1]
                                ?.ds_b_field_name
                            }
                          </div>
                        )}
                      />
                    </div>

                    {/* show/hide Advanced toggle for Matching Rule */}
                    <div className="col-1 d-flex align-items-center justify-content-around  pt-3">
                      <i
                        id={"matchingRuleToggle" + index1}
                        onClick={() => {
                          setOpen((prev) => {
                            return {
                              ...prev,
                              [index1]: !prev[index1],
                            };
                          });
                        }}
                        style={{ color: "#118ab2", cursor: "pointer" }}
                        className={
                          "fa" +
                          (open[index1] ? " fa-chevron-up" : " fa-chevron-down")
                        }
                        aria-hidden="true"
                      ></i>
                      <i
                        onClick={() => {
                          // remove current object
                          values?.matchingRule?.fields?.splice(index1, 1);
                          setFieldValue(
                            `matchingRule.fields`,
                            values?.matchingRule?.fields
                          );
                        }}
                        style={{ cursor: "pointer" }}
                        className={"fa fa-trash text-danger"}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  {/* Advanced Options for Matching Rule starts here*/}
                  <Collapse className={`mt-3`} in={open[index1] || false}>
                    <div>
                      <div className="row mt-3">
                        <div className="col-3">
                          <div className="workflowModalTitle">Label</div>
                          <Field
                            title={item?.label}
                            className="form-control rs-input highlight"
                            type="text"
                            placeholder="Label"
                            name={`matchingRule.fields[${index1}].label`}
                            onChange={(e) =>
                              setFieldValue(
                                `matchingRule.fields[${index1}].label`,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      {/* DSA - FIELD FUNCTIONS STARTS */}
                      <div className="row mt-3">
                        <div className="col-3">
                          <div className="workflowModalTitle">
                            DSA Field Function
                          </div>
                          <div className="form-check py-2">
                            <FormCheck
                              type="switch"
                              name="dsaFieldFunction"
                              style={{
                                zIndex: 0,
                              }}
                              checked={item?.hasOwnProperty("ds_a_field_func")}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setFieldValue(
                                    `matchingRule.fields[${index1}]`,
                                    {
                                      ...values.matchingRule.fields[index1],
                                      ds_a_field_func: "",
                                    }
                                  );
                                } else {
                                  //remove  ds_b_field_value from object
                                  const { ds_a_field_func, ...updatedField } =
                                    values.matchingRule.fields[index1];
                                  // Update the state with the new object
                                  setFieldValue(
                                    `matchingRule.fields[${index1}]`,
                                    updatedField
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                        {/* FOR DSA - VAR function */}
                        {item?.hasOwnProperty("ds_a_field_func") && (
                          <>
                            <div className="col-3">
                              <div className="workflowModalTitle">Function</div>
                              <div
                                title={
                                  operatorOptions?.matchingRuleFunctions?.find(
                                    (option) =>
                                      option.value ===
                                      item?.ds_a_field_func?.split("(")[0]
                                  )?.label
                                }
                              >
                                <Select
                                  options={
                                    operatorOptions?.matchingRuleFunctions
                                  }
                                  name={"ds A Field Function"}
                                  value={
                                    operatorOptions?.matchingRuleFunctions?.find(
                                      (option) =>
                                        option.value ===
                                        item?.ds_a_field_func?.split("(")[0]
                                    ) || {}
                                  }
                                  onChange={(e) => {
                                    const newValue = e?.value;
                                    if (newValue === "VAR") {
                                      setFieldValue(
                                        `matchingRule.fields[${index1}].ds_a_field_func`,
                                        `${newValue}(-0:+0)`
                                      );
                                    } else if (
                                      newValue === "LEFT" ||
                                      newValue === "RIGHT"
                                    ) {
                                      setFieldValue(
                                        `matchingRule.fields[${index1}].ds_a_field_func`,
                                        `${newValue}(0)`
                                      );
                                    } else if (newValue === "SUBSTR") {
                                      setFieldValue(
                                        `matchingRule.fields[${index1}].ds_a_field_func`,
                                        `${newValue}(0:0)`
                                      );
                                    } else if (
                                      newValue === "REMOVE-SPECIAL-CHAR"
                                    ) {
                                      setFieldValue(
                                        `matchingRule.fields[${index1}].ds_a_field_func`,
                                        `${newValue}()`
                                      );
                                    }
                                  }}
                                  isClearable
                                  components={{
                                    ClearIndicator: () => (
                                      <i
                                        style={{
                                          color: "#d9d9d9",
                                          padding: "8px",
                                        }}
                                        className="fa fa-times"
                                        onClick={() =>
                                          setFieldValue(
                                            `matchingRule.fields[${index1}].ds_a_field_func`,
                                            ``
                                          )
                                        }
                                      ></i>
                                    ),
                                    Option: (props) =>
                                      OperatorDescription?.find(
                                        (op) =>
                                          op?.operator == props?.data?.value
                                      )?.description ? (
                                        <OverlayTrigger
                                          placement="right"
                                          overlay={
                                            <Tooltip id="tooltip">
                                              {
                                                OperatorDescription?.find(
                                                  (op) =>
                                                    op?.operator ==
                                                    props?.data?.value
                                                )?.description
                                              }
                                            </Tooltip>
                                          }
                                        >
                                          <div>
                                            <components.Option {...props} />
                                          </div>
                                        </OverlayTrigger>
                                      ) : (
                                        <components.Option {...props} />
                                      ),
                                  }}
                                  menuPosition="fixed" // Make menu fixed
                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                            {item?.ds_a_field_func?.includes("VAR") && (
                              <>
                                <div className="col-2">
                                  <div className="workflowModalTitle">
                                    Minimum
                                  </div>
                                  <div className="input-icon-wrapper">
                                    <span className="input-icon">-</span>
                                    <Field
                                      title={
                                        item?.ds_a_field_func &&
                                        (/-([\d.]+)%/.test(item.ds_a_field_func)
                                          ? item.ds_a_field_func?.match(
                                              /-([\d.]+)%/
                                            )[1]
                                          : /(?<=-)[\d.]+/.test(
                                              item.ds_a_field_func
                                            )
                                          ? item.ds_a_field_func?.match(
                                              /(?<=-)[\d.]+/
                                            )[0]
                                          : "-")
                                      }
                                      className="form-control rs-input highlight with-icon"
                                      type="number"
                                      placeholder="Minimum"
                                      name="Minimum"
                                      value={
                                        item?.ds_a_field_func &&
                                        (/-([\d.]+)%/.test(item.ds_a_field_func)
                                          ? item.ds_a_field_func?.match(
                                              /-([\d.]+)%/
                                            )[1]
                                          : /(?<=-)[\d.]+/.test(
                                              item.ds_a_field_func
                                            )
                                          ? item.ds_a_field_func?.match(
                                              /(?<=-)[\d.]+/
                                            )[0]
                                          : "-")
                                      }
                                      onChange={(e) => {
                                        const minusValue = parseFloat(
                                          e.target.value
                                        );
                                        if (e.target.value === "") {
                                          setFieldValue(
                                            `matchingRule.fields[${index1}].ds_a_field_func`,
                                            item.ds_a_field_func.replace(
                                              /(?<=-)[\d.]+/,
                                              `0`
                                            )
                                          );
                                          return;
                                        }
                                        if (
                                          isNaN(minusValue) ||
                                          minusValue < 0
                                        ) {
                                          return;
                                        }
                                        if (
                                          /%\s*\)/.test(item.ds_a_field_func) &&
                                          minusValue <= 100
                                        ) {
                                          const updatedOperator =
                                            item.ds_a_field_func.replace(
                                              /(?<=-)[\d.]+/,
                                              `${minusValue}`
                                            );
                                          setFieldValue(
                                            `matchingRule.fields[${index1}].ds_a_field_func`,
                                            updatedOperator
                                          );
                                        } else if (
                                          !/%\s*\)/.test(item.ds_a_field_func)
                                        ) {
                                          const updatedOperator =
                                            item.ds_a_field_func.replace(
                                              /(?<=-)[\d.]+/,
                                              `${minusValue}`
                                            );
                                          setFieldValue(
                                            `matchingRule.fields[${index1}].ds_a_field_func`,
                                            updatedOperator
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-2">
                                  <div className="workflowModalTitle">
                                    Maximum
                                  </div>
                                  <div className="input-icon-wrapper">
                                    <span className="input-icon">+</span>
                                    <Field
                                      title={
                                        item?.ds_a_field_func &&
                                        (/\+([\d.]+)%/.test(
                                          item.ds_a_field_func
                                        )
                                          ? item.ds_a_field_func?.match(
                                              /\+([\d.]+)%/
                                            )[1]
                                          : /(?<=\+)[\d.]+/.test(
                                              item.ds_a_field_func
                                            )
                                          ? item.ds_a_field_func?.match(
                                              /(?<=\+)[\d.]+/
                                            )[0]
                                          : "+")
                                      }
                                      className="form-control rs-input highlight with-icon"
                                      type="number"
                                      placeholder="Maximum"
                                      name="Maximum"
                                      value={
                                        item?.ds_a_field_func &&
                                        (/\+([\d.]+)%/.test(
                                          item.ds_a_field_func
                                        )
                                          ? item.ds_a_field_func?.match(
                                              /\+([\d.]+)%/
                                            )[1]
                                          : /(?<=\+)[\d.]+/.test(
                                              item.ds_a_field_func
                                            )
                                          ? item.ds_a_field_func?.match(
                                              /(?<=\+)[\d.]+/
                                            )[0]
                                          : "+")
                                      }
                                      onChange={(e) => {
                                        const plusValue = parseFloat(
                                          e.target.value
                                        );
                                        if (e.target.value === "") {
                                          setFieldValue(
                                            `matchingRule.fields[${index1}].ds_a_field_func`,
                                            item.ds_a_field_func.replace(
                                              /(?<=\+)[\d.]+/,
                                              `0`
                                            )
                                          );
                                          return;
                                        }
                                        if (isNaN(plusValue) || plusValue < 0) {
                                          return;
                                        }
                                        if (
                                          /%\s*\)/.test(item.ds_a_field_func) &&
                                          plusValue <= 100
                                        ) {
                                          const updatedOperator =
                                            item.ds_a_field_func.replace(
                                              /(?<=\+)[\d.]+/,
                                              `${plusValue}`
                                            );
                                          setFieldValue(
                                            `matchingRule.fields[${index1}].ds_a_field_func`,
                                            updatedOperator
                                          );
                                        } else if (
                                          !/%\s*\)/.test(item.ds_a_field_func)
                                        ) {
                                          const updatedOperator =
                                            item.ds_a_field_func.replace(
                                              /(?<=\+)[\d.]+/,
                                              `${plusValue}`
                                            );
                                          setFieldValue(
                                            `matchingRule.fields[${index1}].ds_a_field_func`,
                                            updatedOperator
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                            {item?.ds_a_field_func?.includes("VAR") && (
                              <div className="col-2">
                                <div className="workflowModalTitle">
                                  Variance %
                                </div>
                                <div className="form-check py-2">
                                  <FormCheck
                                    type="switch"
                                    checked={/%\s*\)/.test(
                                      item.ds_a_field_func
                                    )}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setFieldValue(
                                          `matchingRule.fields[${index1}].ds_a_field_func`,
                                          `VAR(-0%:+0%)`
                                        );
                                      } else {
                                        setFieldValue(
                                          `matchingRule.fields[${index1}].ds_a_field_func`,
                                          `VAR(-0:+0)`
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}

                        {/* FOR DSA - LEFT function */}
                        {item?.ds_a_field_func?.includes("LEFT") && (
                          <>
                            <div className="col-2">
                              <div className="workflowModalTitle">
                                No. of Characters
                              </div>
                              <Field
                                title={
                                  item?.ds_a_field_func &&
                                  /(?<=\().*?(?=\))/.test(item.ds_a_field_func)
                                    ? item.ds_a_field_func?.match(
                                        /(?<=\().*?(?=\))/
                                      )[0]
                                    : ""
                                }
                                className="form-control rs-input highlight"
                                type="number"
                                placeholder="No. of Characters"
                                name="NumOfLeftCharacters"
                                value={
                                  item?.ds_a_field_func &&
                                  /(?<=\().*?(?=\))/.test(item.ds_a_field_func)
                                    ? item.ds_a_field_func?.match(
                                        /(?<=\().*?(?=\))/
                                      )[0]
                                    : ""
                                }
                                onChange={(e) => {
                                  if (Number(e.target.value) < 0) {
                                    return;
                                  }
                                  const updatedOperator =
                                    item.ds_a_field_func.replace(
                                      /(?<=\().*?(?=\))/,
                                      `${e.target.value}`
                                    );
                                  setFieldValue(
                                    `matchingRule.fields[${index1}].ds_a_field_func`,
                                    updatedOperator
                                  );
                                }}
                              />
                            </div>
                          </>
                        )}

                        {/* FOR DSA `RIGHT` function */}
                        {item?.ds_a_field_func?.includes("RIGHT") && (
                          <>
                            <div className="col-2">
                              <div className="workflowModalTitle">
                                No. of Characters
                              </div>
                              <Field
                                title={
                                  item?.ds_a_field_func &&
                                  /(?<=\().*?(?=\))/.test(item.ds_a_field_func)
                                    ? item.ds_a_field_func?.match(
                                        /(?<=\().*?(?=\))/
                                      )[0]
                                    : ""
                                }
                                className="form-control rs-input highlight"
                                type="number"
                                placeholder="No. of Characters"
                                name="NumOfRightCharacters"
                                value={
                                  item?.ds_a_field_func &&
                                  /(?<=\().*?(?=\))/.test(item.ds_a_field_func)
                                    ? item.ds_a_field_func?.match(
                                        /(?<=\().*?(?=\))/
                                      )[0]
                                    : ""
                                }
                                onChange={(e) => {
                                  if (Number(e.target.value) < 0) {
                                    return;
                                  }
                                  const updatedOperator =
                                    item.ds_a_field_func.replace(
                                      /(?<=\().*?(?=\))/,
                                      `${e.target.value}`
                                    );
                                  setFieldValue(
                                    `matchingRule.fields[${index1}].ds_a_field_func`,
                                    updatedOperator
                                  );
                                }}
                              />
                            </div>
                          </>
                        )}

                        {/* FOR DSA `SUBSTR` FUNCTION */}
                        {item?.ds_a_field_func?.includes("SUBSTR") && (
                          <>
                            <div className="col-2">
                              <div className="workflowModalTitle">
                                Start Character
                              </div>
                              <Field
                                title={
                                  item?.ds_a_field_func &&
                                  /(?<=\().*?(?=:)/.test(item.ds_a_field_func)
                                    ? item.ds_a_field_func?.match(
                                        /(?<=\().*?(?=:)/
                                      )[0]
                                    : ""
                                }
                                className="form-control rs-input highlight"
                                type="number"
                                placeholder="Start Character"
                                name="startCharacter"
                                value={
                                  item?.ds_a_field_func &&
                                  /(?<=\().*?(?=:)/.test(item.ds_a_field_func)
                                    ? item.ds_a_field_func?.match(
                                        /(?<=\().*?(?=:)/
                                      )[0]
                                    : ""
                                }
                                onChange={(e) => {
                                  if (Number(e.target.value) < 0) {
                                    return;
                                  }
                                  const updatedOperator =
                                    item.ds_a_field_func.replace(
                                      /(?<=\().*?(?=:)/,
                                      `${e.target.value}`
                                    );
                                  setFieldValue(
                                    `matchingRule.fields[${index1}].ds_a_field_func`,
                                    updatedOperator
                                  );
                                }}
                              />
                            </div>

                            <div className="col-2">
                              <div className="workflowModalTitle">
                                End Character
                              </div>
                              <Field
                                title={
                                  item?.ds_a_field_func &&
                                  /(?<=:).*?(?=\))/.test(item.ds_a_field_func)
                                    ? item.ds_a_field_func?.match(
                                        /(?<=:).*?(?=\))/
                                      )[0]
                                    : ""
                                }
                                className="form-control rs-input highlight"
                                type="number"
                                placeholder="End Character"
                                name="endCharacter"
                                value={
                                  item?.ds_a_field_func &&
                                  /(?<=:).*?(?=\))/.test(item.ds_a_field_func)
                                    ? item.ds_a_field_func?.match(
                                        /(?<=:).*?(?=\))/
                                      )[0]
                                    : ""
                                }
                                onChange={(e) => {
                                  if (Number(e.target.value) < 0) {
                                    return;
                                  }
                                  const updatedOperator =
                                    item.ds_a_field_func.replace(
                                      /(?<=:).*?(?=\))/,
                                      `${e.target.value}`
                                    );
                                  setFieldValue(
                                    `matchingRule.fields[${index1}].ds_a_field_func`,
                                    updatedOperator
                                  );
                                }}
                              />
                            </div>
                          </>
                        )}

                        {/* FOR DSA `REMOVE-SPECIAL-CHAR` FUNCTION */}
                        {item?.ds_a_field_func?.includes(
                          "REMOVE-SPECIAL-CHAR"
                        ) && (
                          <>
                            <div className="col-3">
                              <div className="workflowModalTitle">Regex</div>
                              <Field
                                title={
                                  item?.ds_a_field_func?.startsWith(
                                    "REMOVE-SPECIAL-CHAR("
                                  ) && item?.ds_a_field_func?.endsWith(")")
                                    ? item.ds_a_field_func?.slice(20, -1)
                                    : ""
                                }
                                className="form-control rs-input highlight"
                                type="text"
                                placeholder="Regex"
                                name="regex"
                                value={
                                  item?.ds_a_field_func?.startsWith(
                                    "REMOVE-SPECIAL-CHAR("
                                  ) && item?.ds_a_field_func?.endsWith(")")
                                    ? item.ds_a_field_func?.slice(20, -1)
                                    : ""
                                }
                                onChange={(e) => {
                                  setFieldValue(
                                    `matchingRule.fields[${index1}].ds_a_field_func`,
                                    e.target.value
                                      ? `REMOVE-SPECIAL-CHAR(${e.target.value})`
                                      : "REMOVE-SPECIAL-CHAR()"
                                  );
                                }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                      {/* DSA - FIELD FUNCTIONS ENDS  */}

                      {/* DSB - FIELD FUNCTIONS STARTS */}
                      <div className="row mt-3">
                        <div className="col-3">
                          <div className="workflowModalTitle">
                            DSB Field Function
                          </div>
                          <div className="form-check py-2">
                            <FormCheck
                              type="switch"
                              name="dsbFieldFunction"
                              style={{
                                zIndex: 0,
                              }}
                              checked={item?.hasOwnProperty("ds_b_field_func")}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setFieldValue(
                                    `matchingRule.fields[${index1}]`,
                                    {
                                      ...values.matchingRule.fields[index1],
                                      ds_b_field_func: "",
                                    }
                                  );
                                } else {
                                  //remove  ds_b_field_func from object
                                  const { ds_b_field_func, ...updatedField } =
                                    values?.matchingRule?.fields[index1];
                                  // Update the state with the new object
                                  setFieldValue(
                                    `matchingRule.fields[${index1}]`,
                                    updatedField
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                        {/* FOR DSB - VAR function */}
                        {item?.hasOwnProperty("ds_b_field_func") && (
                          <>
                            <div className="col-3">
                              <div className="workflowModalTitle">Function</div>
                              <div
                                title={
                                  operatorOptions?.matchingRuleFunctions?.find(
                                    (option) =>
                                      option.value ===
                                      item?.ds_b_field_func?.split("(")[0]
                                  )?.label
                                }
                              >
                                <Select
                                  options={
                                    operatorOptions?.matchingRuleFunctions
                                  }
                                  name={"ds B Field Function"}
                                  value={
                                    operatorOptions?.matchingRuleFunctions?.find(
                                      (option) =>
                                        option.value ===
                                        item?.ds_b_field_func?.split("(")[0]
                                    ) || {}
                                  }
                                  onChange={(e) => {
                                    const newValue = e?.value;
                                    if (newValue === "VAR") {
                                      setFieldValue(
                                        `matchingRule.fields[${index1}].ds_b_field_func`,
                                        `${newValue}(-0:+0)`
                                      );
                                    } else if (
                                      newValue === "LEFT" ||
                                      newValue === "RIGHT"
                                    ) {
                                      setFieldValue(
                                        `matchingRule.fields[${index1}].ds_b_field_func`,
                                        `${newValue}(0)`
                                      );
                                    } else if (newValue === "SUBSTR") {
                                      setFieldValue(
                                        `matchingRule.fields[${index1}].ds_b_field_func`,
                                        `${newValue}(0:0)`
                                      );
                                    } else if (
                                      newValue === "REMOVE-SPECIAL-CHAR"
                                    ) {
                                      setFieldValue(
                                        `matchingRule.fields[${index1}].ds_b_field_func`,
                                        `${newValue}()`
                                      );
                                    }
                                  }}
                                  isClearable
                                  components={{
                                    ClearIndicator: () => (
                                      <i
                                        style={{
                                          color: "#d9d9d9",
                                          padding: "8px",
                                        }}
                                        className="fa fa-times"
                                        onClick={() =>
                                          setFieldValue(
                                            `matchingRule.fields[${index1}].ds_b_field_func`,
                                            ``
                                          )
                                        }
                                      ></i>
                                    ),
                                    Option: (props) =>
                                      OperatorDescription?.find(
                                        (op) =>
                                          op?.operator == props?.data?.value
                                      )?.description ? (
                                        <OverlayTrigger
                                          placement="right"
                                          overlay={
                                            <Tooltip id="tooltip">
                                              {
                                                OperatorDescription?.find(
                                                  (op) =>
                                                    op?.operator ==
                                                    props?.data?.value
                                                )?.description
                                              }
                                            </Tooltip>
                                          }
                                        >
                                          <div>
                                            <components.Option {...props} />
                                          </div>
                                        </OverlayTrigger>
                                      ) : (
                                        <components.Option {...props} />
                                      ),
                                  }}
                                  menuPosition="fixed" // Make menu fixed
                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                            {item?.ds_b_field_func?.includes("VAR") && (
                              <>
                                <div className="col-2">
                                  <div className="workflowModalTitle">
                                    Minimum
                                  </div>
                                  <div className="input-icon-wrapper">
                                    <span className="input-icon">-</span>
                                    <Field
                                      title={
                                        item?.ds_b_field_func &&
                                        (/-([\d.]+)%/.test(item.ds_b_field_func)
                                          ? item.ds_b_field_func?.match(
                                              /-([\d.]+)%/
                                            )[1]
                                          : /(?<=-)[\d.]+/.test(
                                              item.ds_b_field_func
                                            )
                                          ? item.ds_b_field_func?.match(
                                              /(?<=-)[\d.]+/
                                            )[0]
                                          : "-")
                                      }
                                      className="form-control rs-input highlight with-icon"
                                      type="number"
                                      placeholder="Minimum"
                                      name="Minimum"
                                      value={
                                        item?.ds_b_field_func &&
                                        (/-([\d.]+)%/.test(item.ds_b_field_func)
                                          ? item.ds_b_field_func?.match(
                                              /-([\d.]+)%/
                                            )[1]
                                          : /(?<=-)[\d.]+/.test(
                                              item.ds_b_field_func
                                            )
                                          ? item.ds_b_field_func?.match(
                                              /(?<=-)[\d.]+/
                                            )[0]
                                          : "-")
                                      }
                                      onChange={(e) => {
                                        const minusValue = parseFloat(
                                          e.target.value
                                        );
                                        if (e.target.value === "") {
                                          setFieldValue(
                                            `matchingRule.fields[${index1}].ds_b_field_func`,
                                            item.ds_b_field_func.replace(
                                              /(?<=-)[\d.]+/,
                                              `0`
                                            )
                                          );
                                          return;
                                        }
                                        if (
                                          isNaN(minusValue) ||
                                          minusValue < 0
                                        ) {
                                          return;
                                        }
                                        if (
                                          /%\s*\)/.test(item.ds_b_field_func) &&
                                          minusValue <= 100
                                        ) {
                                          const updatedOperator =
                                            item.ds_b_field_func.replace(
                                              /(?<=-)[\d.]+/,
                                              `${minusValue}`
                                            );
                                          setFieldValue(
                                            `matchingRule.fields[${index1}].ds_b_field_func`,
                                            updatedOperator
                                          );
                                        } else if (
                                          !/%\s*\)/.test(item.ds_b_field_func)
                                        ) {
                                          const updatedOperator =
                                            item.ds_b_field_func.replace(
                                              /(?<=-)[\d.]+/,
                                              `${minusValue}`
                                            );
                                          setFieldValue(
                                            `matchingRule.fields[${index1}].ds_b_field_func`,
                                            updatedOperator
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-2">
                                  <div className="workflowModalTitle">
                                    Maximum
                                  </div>
                                  <div className="input-icon-wrapper">
                                    <span className="input-icon">+</span>
                                    <Field
                                      title={
                                        item?.ds_b_field_func &&
                                        (/\+([\d.]+)%/.test(
                                          item.ds_b_field_func
                                        )
                                          ? item.ds_b_field_func?.match(
                                              /\+([\d.]+)%/
                                            )[1]
                                          : /(?<=\+)[\d.]+/.test(
                                              item.ds_b_field_func
                                            )
                                          ? item.ds_b_field_func?.match(
                                              /(?<=\+)[\d.]+/
                                            )[0]
                                          : "+")
                                      }
                                      className="form-control rs-input highlight with-icon"
                                      type="number"
                                      placeholder="Maximum"
                                      name="Maximum"
                                      value={
                                        item?.ds_b_field_func &&
                                        (/\+([\d.]+)%/.test(
                                          item.ds_b_field_func
                                        )
                                          ? item.ds_b_field_func?.match(
                                              /\+([\d.]+)%/
                                            )[1]
                                          : /(?<=\+)[\d.]+/.test(
                                              item.ds_b_field_func
                                            )
                                          ? item.ds_b_field_func?.match(
                                              /(?<=\+)[\d.]+/
                                            )[0]
                                          : "+")
                                      }
                                      onChange={(e) => {
                                        const plusValue = parseFloat(
                                          e.target.value
                                        );
                                        if (e.target.value === "") {
                                          setFieldValue(
                                            `matchingRule.fields[${index1}].ds_b_field_func`,
                                            item.ds_b_field_func.replace(
                                              /(?<=\+)[\d.]+/,
                                              `0`
                                            )
                                          );
                                          return;
                                        }
                                        if (isNaN(plusValue) || plusValue < 0) {
                                          return;
                                        }
                                        if (
                                          /%\s*\)/.test(item.ds_b_field_func) &&
                                          plusValue <= 100
                                        ) {
                                          const updatedOperator =
                                            item.ds_b_field_func.replace(
                                              /(?<=\+)[\d.]+/,
                                              `${plusValue}`
                                            );
                                          setFieldValue(
                                            `matchingRule.fields[${index1}].ds_b_field_func`,
                                            updatedOperator
                                          );
                                        } else if (
                                          !/%\s*\)/.test(item.ds_b_field_func)
                                        ) {
                                          const updatedOperator =
                                            item.ds_b_field_func.replace(
                                              /(?<=\+)[\d.]+/,
                                              `${plusValue}`
                                            );
                                          setFieldValue(
                                            `matchingRule.fields[${index1}].ds_b_field_func`,
                                            updatedOperator
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                            {item?.ds_b_field_func?.includes("VAR") && (
                              <div className="col-2">
                                <div className="workflowModalTitle">
                                  Variance %
                                </div>
                                <div className="form-check py-2">
                                  <FormCheck
                                    type="switch"
                                    checked={/%\s*\)/.test(
                                      item.ds_b_field_func
                                    )}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setFieldValue(
                                          `matchingRule.fields[${index1}].ds_b_field_func`,
                                          `VAR(-0%:+0%)`
                                        );
                                      } else {
                                        setFieldValue(
                                          `matchingRule.fields[${index1}].ds_b_field_func`,
                                          `VAR(-0:+0)`
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}

                        {/* FOR DSA - LEFT function */}
                        {item?.ds_b_field_func?.includes("LEFT") && (
                          <>
                            <div className="col-2">
                              <div className="workflowModalTitle">
                                No. of Characters
                              </div>
                              <Field
                                title={
                                  item?.ds_b_field_func &&
                                  /(?<=\().*?(?=\))/.test(item.ds_b_field_func)
                                    ? item.ds_b_field_func?.match(
                                        /(?<=\().*?(?=\))/
                                      )[0]
                                    : ""
                                }
                                className="form-control rs-input highlight"
                                type="number"
                                placeholder="No. of Characters"
                                name="NumOfLeftCharacters"
                                value={
                                  item?.ds_b_field_func &&
                                  /(?<=\().*?(?=\))/.test(item.ds_b_field_func)
                                    ? item.ds_b_field_func?.match(
                                        /(?<=\().*?(?=\))/
                                      )[0]
                                    : ""
                                }
                                onChange={(e) => {
                                  if (Number(e.target.value) < 0) {
                                    return;
                                  }
                                  const updatedOperator =
                                    item.ds_b_field_func.replace(
                                      /(?<=\().*?(?=\))/,
                                      `${e.target.value}`
                                    );
                                  setFieldValue(
                                    `matchingRule.fields[${index1}].ds_b_field_func`,
                                    updatedOperator
                                  );
                                }}
                              />
                            </div>
                          </>
                        )}

                        {/* FOR DSA `RIGHT` function */}
                        {item?.ds_b_field_func?.includes("RIGHT") && (
                          <>
                            <div className="col-2">
                              <div className="workflowModalTitle">
                                No. of Characters
                              </div>
                              <Field
                                title={
                                  item?.ds_b_field_func &&
                                  /(?<=\().*?(?=\))/.test(item.ds_b_field_func)
                                    ? item.ds_b_field_func?.match(
                                        /(?<=\().*?(?=\))/
                                      )[0]
                                    : ""
                                }
                                className="form-control rs-input highlight"
                                type="number"
                                placeholder="No. of Characters"
                                name="NumOfRightCharacters"
                                value={
                                  item?.ds_b_field_func &&
                                  /(?<=\().*?(?=\))/.test(item.ds_b_field_func)
                                    ? item.ds_b_field_func?.match(
                                        /(?<=\().*?(?=\))/
                                      )[0]
                                    : ""
                                }
                                onChange={(e) => {
                                  if (Number(e.target.value) < 0) {
                                    return;
                                  }
                                  const updatedOperator =
                                    item.ds_b_field_func.replace(
                                      /(?<=\().*?(?=\))/,
                                      `${e.target.value}`
                                    );
                                  setFieldValue(
                                    `matchingRule.fields[${index1}].ds_b_field_func`,
                                    updatedOperator
                                  );
                                }}
                              />
                            </div>
                          </>
                        )}

                        {/* FOR DSA `SUBSTR` FUNCTION */}
                        {item?.ds_b_field_func?.includes("SUBSTR") && (
                          <>
                            <div className="col-2">
                              <div className="workflowModalTitle">
                                Start Character
                              </div>
                              <Field
                                title={
                                  item?.ds_b_field_func &&
                                  /(?<=\().*?(?=:)/.test(item.ds_b_field_func)
                                    ? item.ds_b_field_func?.match(
                                        /(?<=\().*?(?=:)/
                                      )[0]
                                    : ""
                                }
                                className="form-control rs-input highlight"
                                type="number"
                                placeholder="Start Character"
                                name="startCharacter"
                                value={
                                  item?.ds_b_field_func &&
                                  /(?<=\().*?(?=:)/?.test(item.ds_b_field_func)
                                    ? item.ds_b_field_func?.match(
                                        /(?<=\().*?(?=:)/
                                      )[0]
                                    : ""
                                }
                                onChange={(e) => {
                                  if (Number(e.target.value) < 0) {
                                    return;
                                  }
                                  const updatedOperator =
                                    item.ds_b_field_func.replace(
                                      /(?<=\().*?(?=:)/,
                                      `${e.target.value}`
                                    );
                                  setFieldValue(
                                    `matchingRule.fields[${index1}].ds_b_field_func`,
                                    updatedOperator
                                  );
                                }}
                              />
                            </div>

                            <div className="col-2">
                              <div className="workflowModalTitle">
                                End Character
                              </div>
                              <Field
                                title={
                                  item?.ds_b_field_func &&
                                  /(?<=:).*?(?=\))/.test(item.ds_b_field_func)
                                    ? item.ds_b_field_func?.match(
                                        /(?<=:).*?(?=\))/
                                      )[0]
                                    : ""
                                }
                                className="form-control rs-input highlight"
                                type="number"
                                placeholder="End Character"
                                name="endCharacter"
                                value={
                                  item?.ds_b_field_func &&
                                  /(?<=:).*?(?=\))/.test(item.ds_b_field_func)
                                    ? item.ds_b_field_func?.match(
                                        /(?<=:).*?(?=\))/
                                      )[0]
                                    : ""
                                }
                                onChange={(e) => {
                                  if (Number(e.target.value) < 0) {
                                    return;
                                  }
                                  const updatedOperator =
                                    item.ds_b_field_func.replace(
                                      /(?<=:).*?(?=\))/,
                                      `${e.target.value}`
                                    );
                                  setFieldValue(
                                    `matchingRule.fields[${index1}].ds_b_field_func`,
                                    updatedOperator
                                  );
                                }}
                              />
                            </div>
                          </>
                        )}

                        {/* FOR DSB `REMOVE-SPECIAL-CHAR` FUNCTION */}
                        {item?.ds_b_field_func?.includes(
                          "REMOVE-SPECIAL-CHAR"
                        ) && (
                          <>
                            <div className="col-3">
                              <div className="workflowModalTitle">Regex</div>
                              <Field
                                title={
                                  item?.ds_b_field_func?.startsWith(
                                    "REMOVE-SPECIAL-CHAR("
                                  ) && item?.ds_b_field_func?.endsWith(")")
                                    ? item.ds_b_field_func?.slice(20, -1)
                                    : ""
                                }
                                className="form-control rs-input highlight"
                                type="text"
                                placeholder="Regex"
                                name="regex"
                                value={
                                  item?.ds_b_field_func?.startsWith(
                                    "REMOVE-SPECIAL-CHAR("
                                  ) && item?.ds_b_field_func?.endsWith(")")
                                    ? item.ds_b_field_func?.slice(20, -1)
                                    : ""
                                }
                                onChange={(e) => {
                                  setFieldValue(
                                    `matchingRule.fields[${index1}].ds_b_field_func`,
                                    e.target.value
                                      ? `REMOVE-SPECIAL-CHAR(${e.target.value})`
                                      : "REMOVE-SPECIAL-CHAR()"
                                  );
                                }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                      {/* DSB FIELD FUNCTIONS ENDS  */}
                    </div>
                  </Collapse>
                </div>
              ))}
          </>
        )}
      </FieldArray>
    </div>
  );
};

export default PairExMatchingRule;
