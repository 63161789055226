import moment from "moment";
export const mapFilters = (filters, defaultValues) => {
  let obj = {};
  filters
    .filter((item) => item.filterName !== "region")
    .forEach((filterIn) => {
      if (filterIn.filterName !== "date_range") {
        obj = {
          ...obj,
          [filterIn.filterName]: {
            data:
              defaultValues && defaultValues[filterIn.filterName]
                ? new Date(defaultValues[filterIn.filterName])
                : filterIn.filterName === "business_date" ||
                  filterIn.filterName === "business_day" ||
                  filterIn.filterName === "current_date" ||
                  filterIn.filterName === "calendar_date" ||
                  filterIn.filterName === "date"
                ? filterIn.filterDefaultValue
                  ? new Date(
                      filterIn.filterDefaultValue?.split("-")[0],
                      filterIn.filterDefaultValue?.split("-")[1] - 1,
                      filterIn.filterDefaultValue?.split("-")[2]
                    )
                  : new Date(moment().subtract(1, "days").format("YYYY-MM-DD"))
                : filterIn.filterData.map((obj) => ({
                    ...obj,
                    value: obj.codeValue,
                  })),
            filter:
              defaultValues && defaultValues[filterIn.filterName]
                ? [defaultValues[filterIn.filterName]]
                : filterIn.filterName === "business_date" ||
                  filterIn.filterName === "business_day" ||
                  filterIn.filterName === "current_date" ||
                  filterIn.filterName === "calendar_date" ||
                  filterIn.filterName === "date"
                ? [
                    filterIn.filterDefaultValue
                      ? filterIn.filterDefaultValue
                      : moment().subtract(1, "days").format("YYYY-MM-DD"),
                  ]
                : filterIn.filterData
                    .filter((obj) => obj.param1 === "1")
                    .map((obj) => obj.codeValue),
          },
        };
      } else {
        obj = {
          ...obj,
          [filterIn.filterName]: {
            data:
              defaultValues && defaultValues[filterIn.filterName]
                ? new Date(defaultValues[filterIn.filterName])
                : filterIn.filterName === "date_range"
                ? filterIn.filterDefaultValue
                  ? [
                      new Date(filterIn.filterDefaultValue),
                      new Date(filterIn.filterDefaultValue),
                    ]
                  : [
                      new Date(
                        moment().subtract(1, "days").format("YYYY-MM-DD")
                      ),
                      new Date(
                        moment().subtract(1, "days").format("YYYY-MM-DD")
                      ),
                    ]
                : filterIn.filterData.map((obj) => ({
                    ...obj,
                    value: obj.codeValue,
                  })),
            filter:
              defaultValues && defaultValues[filterIn.filterName]
                ? [defaultValues[filterIn.filterName]]
                : filterIn.filterName === "date_range"
                ? [
                    filterIn.filterDefaultValue
                      ? filterIn.filterDefaultValue
                      : moment().subtract(5, "days").format("YYYY-MM-DD"),
                    moment().format("YYYY-MM-DD"),
                  ]
                : filterIn.filterData
                    .filter((obj) => obj.param1 === "1")
                    .map((obj) => obj.codeValue),
          },
        };
      }
    });
  return obj;
};

export const handleFilterChange = (e, type, filter, setFilter) => {
  let obj = { ...filter };
  if (
    type === "business_date" ||
    type === "business_day" ||
    type === "current_date" ||
    type === "calendar_date" ||
    type === "date"
  ) {
    obj[type] = { ...obj[type], filter: [e] };
  } else if (type === "date_range") {
    obj[type] = { ...obj[type], filter: [...e] };
  } else {
    if (e.isSingle) {
      if (e.flag) obj[type].filter = [e.id];
      else obj[type].filter = obj[type].filter.filter((id) => id !== e.id);
    } else {
      if (e.flag) obj[type].filter.push(e.id);
      else obj[type].filter = obj[type].filter.filter((id) => id !== e.id);
    }
  }
  setFilter(obj);
};

export const filtersParams = (filter, regionFilter, flag) => {
  let allFilters = regionFilter ? regionFilter : "";
  for (let key in filter) {
    if (filter[key]?.filter?.length > 0) {
      if (key === "date_range") {
        allFilters += `&from_date=${filter[key]?.filter[0]}&to_date=${filter[key]?.filter[1]}`;
      }
      allFilters += `&${key}=${filter[key]?.filter?.join(",")}`;
    }
  }
  if (flag) {
    allFilters = allFilters.substring(1);
  }
  return allFilters;
};

export const handleNewFilter = (
  e,
  filterData,
  showFilters,
  filter,
  setFilter,
  setFilterData
) => {
  const data = [...filterData];
  if (e.flag) {
    data.push(showFilters.find((x) => x.filterId === e.id));
    showFilters.map((eachFilter) => {
      setFilter({
        ...filter,
        [eachFilter.filterName]: {
          data: eachFilter.filterData.map((obj) => ({
            ...obj,
            value: obj.codeValue,
          })),
          filter: eachFilter.filterData
            .filter((obj) => obj.param1 === "1")
            .map((obj) => obj.codeValue),
        },
      });
    });
  } else {
    data.splice(
      data.findIndex((x) => x.filterId === e.id),
      1
    );
    const obj = mapFilters(data);
    setFilter(obj);
  }
  setFilterData(data);
};
