import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select, { components } from "react-select";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import axiosInstance from "../../../utils/axiosInstance";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import NewFilter from "../../../components/FilterDropdown/NewFilter";
import Toast from "../../../components/Toast/Toast";

const AddPairModal = ({
  Recon,
  setShowModal,
  showModal,
  setSelectedData,
  setIsRefresh,
}) => {
  const [loading, setLoading] = useState(false);
  const [dataSources, setDataSources] = useState([]);
  const [toRecordTypes, setToRecordTypes] = useState([]);
  const [fromRecordTypes, setFromRecordTypes] = useState([]);
  const [selectedToRecordTypes, setSelectedToRecordTypes] = useState(null);
  const [selectedFromRecordTypes, setSelectedFromRecordTypes] = useState(null);
  const initialValues = {
    fromDatasource: "",
    toDatasource: "",
    fromDatasourceId: "",
    toDatasourceId: "",
    dynamic: false,
    enabled: true,
    tolerance: "",
    matching: "",
    batchSize: "",
    seq: "",
    allowMMEntries: true,
    dsALocateTxnSearchEnabled: true,
    dsBLocateTxnSearchEnabled: true,
  };

  const matchingOptions = [
    {
      value: "1:1",
      label: "1:1",
    },
    {
      value: "1:M",
      label: "1:M",
    },
    {
      value: "M:1",
      label: "M:1",
    },
    {
      value: "M:M",
      label: "M:M",
    },
    {
      value: "1:MAG",
      label: "1:MAG",
    },
  ];

  const errorStyling = {
    position: "absolute",
    transform: "translateY(2px)",
    fontSize: "12px",
    top: "100%",
    color: "red",
    left: 10,
  };
  const validationSchema = Yup.object().shape({
    fromDatasource: Yup.string().required("From Datasource is required"),
    toDatasource: Yup.string().required("To Datasource is required"),
    fromDatasourceId: Yup.string().required("From Datasource ID is required"),
    toDatasourceId: Yup.string().required("To Datasource ID is required"),
    dynamic: Yup.boolean().required("Dynamic field is required"),
    enabled: Yup.boolean().required("Enabled field is required"),
    tolerance: Yup.number()
      .typeError("Only Numbers allowed!")
      .required("Tolerance is Required*"),
    matching: Yup.string().required("Relationship is required"),
    batchSize: Yup.number()
      .typeError("Only Numbers allowed!")
      .required("Batch size is Required*"),
    allowMMEntries: Yup.boolean().required(
      "Allow MM Entries field is required"
    ),
    dsALocateTxnSearchEnabled: Yup.boolean().required(
      "DSA Locate Transaction is required"
    ),
    dsBLocateTxnSearchEnabled: Yup.boolean().required(
      "DSB Locate Transaction is required"
    ),
  });

  const toDatasourceRecordTypes = (toDatasourceId) => {
    axiosInstance
      .get("recon/wfm-config-fields?masterDatasourceId=" + toDatasourceId)
      .then((res) => {
        if (res?.message?.status == 200) {
          setToRecordTypes(
            res?.data?.interfaceRecordTypes?.map((x) => ({
              label: x.recordType,
              value: x.recordType,
              ...x,
            }))
          );
        } else {
          setToRecordTypes([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fromDatasourceRecordTypes = (fromDatasourceId) => {
    axiosInstance
      .get("recon/wfm-config-fields?masterDatasourceId=" + fromDatasourceId)
      .then((res) => {
        if (res?.message?.status == 200) {
          setFromRecordTypes(
            res?.data?.interfaceRecordTypes?.map((x) => ({
              label: x.recordType,
              value: x.recordType,
              ...x,
            }))
          );
        } else {
          setFromRecordTypes([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get("/interfaces/datasources")
      .then((res) => {
        setDataSources(
          res.data.datasources.map((x) => ({ ...x, label: x.datasource })) || []
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, []);

  const handleAdd = useCallback(async (values) => {
    setLoading(true);
    const newWorkflowStep = {
      allowMMEntries:
        values?.allowMMEntries.toString() === "true" ? true : false,
      batchSize: values?.batchSize,
      dynamic: values?.dynamic.toString() === "true" ? true : false,
      enabled: values?.enabled.toString() === "true" ? true : false,
      fromDatasource: values?.fromDatasource,
      fromDatasourceId: values?.fromDatasourceId,
      matching: values?.matching,
      seq: 0,
      toDatasource: values?.toDatasource,
      toDatasourceId: values?.toDatasourceId,
      tolerance: values?.tolerance,
      dsAInterfaceFormatIds:
        selectedFromRecordTypes?.map((item) => item?.interfaceFormatId) || [],
      dsAInterfaceIds:
        selectedFromRecordTypes?.map((item) => item?.interfaceId) || [],
      dsBInterfaceFormatIds:
        selectedToRecordTypes?.map((item) => item?.interfaceFormatId) || [],
      dsBInterfaceIds:
        selectedToRecordTypes?.map((item) => item?.interfaceId) || [],
      dsALocateTxnSearchEnabled:
        values?.dsALocateTxnSearchEnabled?.toString() === "true" ? true : false,
      dsBLocateTxnSearchEnabled:
        values?.dsBLocateTxnSearchEnabled?.toString() === "true" ? true : false,
    };

    const data = {
      data: {
        id: Recon?.id,
        reconName: Recon?.name,
        workflow: [newWorkflowStep],
      },
    };
    try {
      const response = await axiosInstance.post(`/recon/pairs`, data);
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      }
      setSelectedData(response?.data?.workflow[0] || null);
      setLoading(false);
      setShowModal(false);
      setIsRefresh(true);
      Toast(response?.message?.description, "success");
    } catch (err) {
      Toast(err?.response?.data?.message?.description, "error");
    }
  });
  return (
    <div className="col-12">
      {loading && (
        <div className="spinner-center" style={{ top: "0%", left: "0%" }}>
          <LoaderComponent />
        </div>
      )}
      <Modal
        backdrop={true}
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <h3 className="color-dark">Add Pair</h3>
        </Modal.Header>
        <Modal.Body scrollable="true">
          <div className="col-12">
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                if (
                  dataSources?.find((r) => r.id === values.fromDatasourceId)
                    ?.isPostStagingTable == false &&
                  !selectedFromRecordTypes
                ) {
                  Toast("Please select DSA Record Type", "error");
                  return;
                }

                if (
                  dataSources?.find((r) => r.id === values.toDatasourceId)
                    ?.isPostStagingTable == false &&
                  !selectedToRecordTypes
                ) {
                  Toast("Please select DSB Record Type", "error");
                  return;
                }
                setSubmitting(true);
                handleAdd(values);
              }}
            >
              {({ errors, values, setFieldValue, handleSubmit }) => (
                <Form id="add-recon" className="form" onSubmit={handleSubmit}>
                  <div className="col-12 d-flex p-0">
                    <div className="col-6">
                      <div className="workflowModalTitle">Data Source A</div>
                      <NewFilter
                        id="fromDatasource"
                        title={
                          dataSources.find(
                            (r) => r.id === values.fromDatasourceId
                          )?.datasource ?? "Data Source A"
                        }
                        type={"checkbox"}
                        data={dataSources}
                        field={"id"}
                        filter={values.fromDatasourceId}
                        isSearchable
                        searchField="datasource"
                        onChange={(e) => {
                          setFieldValue("fromDatasourceId", e);
                          setFieldValue(
                            "fromDatasource",
                            dataSources?.find((r) => r.id === e)?.datasource
                          );
                          setFromRecordTypes([]);
                          if (
                            dataSources?.find((r) => r.id === e)
                              ?.isPostStagingTable == false
                          ) {
                            setSelectedFromRecordTypes(null);
                            fromDatasourceRecordTypes(e);
                          }
                        }}
                      />
                      <ErrorMessage
                        component={() => (
                          <div style={errorStyling}>
                            {errors.fromDatasource}
                          </div>
                        )}
                        name="fromDatasource"
                      />
                    </div>
                    <div className="col-6">
                      <div className="workflowModalTitle">Data Source B</div>
                      <NewFilter
                        id="toDatasource"
                        title={
                          dataSources.find(
                            (r) => r.id === values.toDatasourceId
                          )?.datasource ?? "Data Source B"
                        }
                        type={"checkbox"}
                        data={dataSources}
                        field={"id"}
                        filter={values.toDatasourceId}
                        isSearchable
                        searchField="datasource"
                        onChange={(e) => {
                          setFieldValue("toDatasourceId", e);
                          setFieldValue(
                            "toDatasource",
                            dataSources?.find((r) => r.id === e)?.datasource
                          );
                          setToRecordTypes([]);
                          if (
                            dataSources?.find((r) => r.id === e)
                              ?.isPostStagingTable == false
                          ) {
                            setSelectedToRecordTypes(null);
                            toDatasourceRecordTypes(e);
                          }
                        }}
                      />
                      <ErrorMessage
                        component={() => (
                          <div style={errorStyling}>{errors.toDatasource}</div>
                        )}
                        name="toDatasource"
                      />
                    </div>
                  </div>
                  <div className="col-12 d-flex p-0 mt-4">
                    {dataSources?.find((r) => r.id === values.fromDatasourceId)
                      ?.isPostStagingTable == false && (
                      <div className="col-6">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="button-tooltip-2">
                              The DSA record type configured for this data
                              interface.
                            </Tooltip>
                          }
                        >
                          <div>
                            <div className="workflowModalTitle">
                              DSA Record Type
                            </div>
                            <Select
                              id="fromRecordTypes"
                              classNamePrefix={"fromRecordTypes"}
                              options={fromRecordTypes}
                              value={selectedFromRecordTypes}
                              onChange={(e) => {
                                setSelectedFromRecordTypes(e);
                              }}
                              placeholder={"Select Record Type"}
                              isMulti
                            />
                          </div>
                        </OverlayTrigger>
                      </div>
                    )}
                    {dataSources?.find((r) => r.id === values.toDatasourceId)
                      ?.isPostStagingTable == false && (
                      <div className="col-6">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="button-tooltip-2">
                              The DSB record type configured for this data
                              interface.
                            </Tooltip>
                          }
                        >
                          <div>
                            <div className="workflowModalTitle">
                              DSB Record Type
                            </div>
                            <Select
                              id="toRecordTypes"
                              classNamePrefix={"toRecordTypes"}
                              options={toRecordTypes}
                              value={selectedToRecordTypes}
                              onChange={(e) => {
                                setSelectedToRecordTypes(e);
                              }}
                              placeholder={"Select Record Type"}
                              isMulti
                            />
                          </div>
                        </OverlayTrigger>
                      </div>
                    )}
                  </div>
                  <div className="col-12 d-flex p-0 mt-3">
                    <div className="col-6">
                      <div className="workflowModalTitle">Relationship</div>
                      <Select
                        id="matchingModal"
                        classNamePrefix={"matching"}
                        options={matchingOptions}
                        name={"relationship"}
                        value={
                          matchingOptions
                            ? matchingOptions.find(
                                (option) => option.value === values.matching
                              )
                            : ""
                        }
                        onChange={(e) => {
                          setFieldValue("matching", e.value);
                        }}
                        placeholder={"Select"}
                        components={{
                          Option: (props) =>
                            props?.data?.value === "1:1" ||
                            props?.data?.value === "1:M" ||
                            props?.data?.value === "M:1" ||
                            props?.data?.value === "M:M" ? (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip">
                                    {props?.data?.value === "1:1"
                                      ? "The system expects that at most one DSB record should correspond to each DSA record."
                                      : props?.data?.value === "1:M"
                                      ? "The system expects one or multiple DSB records to correspond to each DSA record."
                                      : props?.data?.value === "M:1"
                                      ? "The system expects at most one DSB record to correspond to one or multiple DSA records."
                                      : props?.data?.value === "M:M"
                                      ? "The system expects one or multiple DSB records to correspond to one or multiple DSA records."
                                      : ""}
                                  </Tooltip>
                                }
                              >
                                <div>
                                  <components.Option {...props} />
                                </div>
                              </OverlayTrigger>
                            ) : (
                              <components.Option {...props} />
                            ),
                        }}
                      />
                      <ErrorMessage
                        component={() => (
                          <div style={errorStyling}>{errors.matching}</div>
                        )}
                        name="matching"
                      />
                    </div>
                    <div className="col-3">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            The number of days the system will attempt to find a
                            missing transaction before ignoring it.
                          </Tooltip>
                        }
                      >
                        <div>
                          <div className="workflowModalTitle">
                            Missing Tolerance
                          </div>
                          <Field
                            id="toleranceModal"
                            className={`form-control rs-input`}
                            type="text"
                            placeholder="Tolerance"
                            name="tolerance"
                          />
                        </div>
                      </OverlayTrigger>
                      <ErrorMessage
                        component={() => (
                          <div style={errorStyling}>{errors.tolerance}</div>
                        )}
                        name="tolerance"
                      />
                    </div>
                    <div className="col-3">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            The number of records processed together in one
                            batch during reconciliation.
                          </Tooltip>
                        }
                      >
                        <div>
                          <div className="workflowModalTitle">Batch Size</div>
                          <Field
                            id="batchSizeModal"
                            className={`form-control rs-input
                          }`}
                            type="text"
                            placeholder="Batch Size"
                            name="batchSize"
                          />
                        </div>
                      </OverlayTrigger>
                      <ErrorMessage
                        component={() => (
                          <div style={errorStyling}>{errors.batchSize}</div>
                        )}
                        name="batchSize"
                      />
                    </div>
                  </div>
                  <div className="col-12 d-flex p-0 mt-4">
                    <div className="col-2">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            Enabled to ensure that the DSA destination matches
                            the DSB configuration.
                          </Tooltip>
                        }
                      >
                        <div>
                          <div className="workflowModalTitle">Dynamic</div>
                          <div className="form-check">
                            <Field
                              id="dynamicTrueModal"
                              className="form-check-input"
                              type="radio"
                              name="dynamic"
                              value="true"
                              checked={
                                values?.dynamic === "true" ||
                                values?.dynamic === true
                              }
                            />

                            <label className="form-check-label">True</label>
                          </div>

                          <div className="form-check">
                            <Field
                              id="dynamicFalseModal"
                              className="form-check-input"
                              type="radio"
                              name="dynamic"
                              value="false"
                              checked={
                                values?.dynamic === "false" ||
                                values?.dynamic === false
                              }
                            />
                            <label className="form-check-label">False</label>
                          </div>
                        </div>
                      </OverlayTrigger>
                    </div>
                    <div className="col-2">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            Enabled to allow Manual Matching adjustment
                            operation on this Recon Pair.
                          </Tooltip>
                        }
                      >
                        <div>
                          <div className="workflowModalTitle">
                            Allow MM Entries
                          </div>
                          <div className="form-check">
                            <Field
                              id="allowMMEntriesTrueModal"
                              className="form-check-input"
                              type="radio"
                              name="allowMMEntries"
                              value="true"
                              checked={
                                values?.allowMMEntries === true ||
                                values?.allowMMEntries === "true"
                              }
                            />

                            <label className="form-check-label">True</label>
                          </div>

                          <div className="form-check">
                            <Field
                              id="allowMMEntriesFalseModal"
                              className="form-check-input"
                              type="radio"
                              name="allowMMEntries"
                              value="false"
                              checked={
                                values?.allowMMEntries === false ||
                                values?.allowMMEntries === "false"
                              }
                            />
                            <label className="form-check-label">False</label>
                          </div>
                        </div>
                      </OverlayTrigger>
                    </div>
                    <div className="col-2">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            Indicates whether the Recon Pair is active or
                            inactive.
                          </Tooltip>
                        }
                      >
                        <div>
                          <div className="workflowModalTitle">Enabled</div>
                          <div className="form-check">
                            <Field
                              id="enabledTrueModal"
                              className="form-check-input"
                              type="radio"
                              name="enabled"
                              value="true"
                              checked={
                                values?.enabled === "true" ||
                                values?.enabled === true
                              }
                            />

                            <label className="form-check-label">True</label>
                          </div>

                          <div className="form-check">
                            <Field
                              id="enabledFalseModal"
                              className="form-check-input"
                              type="radio"
                              name="enabled"
                              value="false"
                              checked={
                                values?.enabled === "false" ||
                                values?.enabled === false
                              }
                            />
                            <label className="form-check-label">False</label>
                          </div>
                        </div>
                      </OverlayTrigger>
                    </div>
                    <div className="col-3">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            Flag to enable or disable search functionality in
                            the respective data source during the locate
                            transaction flow.
                          </Tooltip>
                        }
                      >
                        <div>
                          <div className="workflowModalTitle">
                            DSA Locate Transaction
                          </div>
                          <div className="form-check">
                            <Field
                              id="dsALocateTxnSearchEnabledTrueModal"
                              className="form-check-input"
                              type="radio"
                              name="dsALocateTxnSearchEnabled"
                              value="true"
                              checked={
                                values?.dsALocateTxnSearchEnabled === "true" ||
                                values?.dsALocateTxnSearchEnabled === true
                              }
                            />

                            <label className="form-check-label">True</label>
                          </div>

                          <div className="form-check">
                            <Field
                              id="dsALocateTxnSearchEnabledFalseModal"
                              className="form-check-input"
                              type="radio"
                              name="dsALocateTxnSearchEnabled"
                              value="false"
                              checked={
                                values?.dsALocateTxnSearchEnabled === "false" ||
                                values?.dsALocateTxnSearchEnabled === false
                              }
                            />
                            <label className="form-check-label">False</label>
                          </div>
                        </div>
                      </OverlayTrigger>
                    </div>
                    <div className="col-3">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            Flag to enable or disable search functionality in
                            the respective data source during the locate
                            transaction flow.
                          </Tooltip>
                        }
                      >
                        <div>
                          <div className="workflowModalTitle">
                            DSB Locate Transaction
                          </div>
                          <div className="form-check">
                            <Field
                              id="dsBLocateTxnSearchEnabledTrueModal"
                              className="form-check-input"
                              type="radio"
                              name="dsBLocateTxnSearchEnabled"
                              value="true"
                              checked={
                                values?.dsBLocateTxnSearchEnabled === "true" ||
                                values?.dsBLocateTxnSearchEnabled === true
                              }
                            />

                            <label className="form-check-label">True</label>
                          </div>

                          <div className="form-check">
                            <Field
                              id="dsBLocateTxnSearchEnabledFalseModal"
                              className="form-check-input"
                              type="radio"
                              name="dsBLocateTxnSearchEnabled"
                              value="false"
                              checked={
                                values?.dsBLocateTxnSearchEnabled === "false" ||
                                values?.dsBLocateTxnSearchEnabled === false
                              }
                            />
                            <label className="form-check-label">False</label>
                          </div>
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="offset-9 col-3 d-flex justify-content-between">
                      <div className="col-5">
                        <ButtonBasic
                          id="cancelModal"
                          title="Cancel"
                          onClick={() => setShowModal(false)}
                        />
                      </div>
                      <div className="col-5">
                        <ButtonBasic
                          id="saveModal"
                          title="Save"
                          type="submit"
                          onClick={handleSubmit}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddPairModal;
