import React from "react";
import axiosInstance from "../../../../utils/axiosInstance";
import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";
import Toast from "../../../../components/Toast/Toast";
const ResetPasswordModal = ({
  user_Id,
  userName,
  userEmail,
  showPasswordModal,
  setShowPasswordModal,
  showConfirmationModal,
  setShowConfirmationModal,
  showReset2FAModal,
  setShowReset2FAModal,
  getUsers,
}) => {
  const changePassword = () => {
    const changePasswordObject = {
      username: userEmail,
    };
    axiosInstance
      .post("/users/reset-password", { data: changePasswordObject })
      .then((res) => {
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
        } else {
          // console.log(res);
          setShowPasswordModal(false);
          getUsers();
          Toast(res.message.description, "success");
        }
      })
      .catch((err) => {
        console.log("error", err);
        Toast(err.response.data.message.description, "error");
      });
  };

  const reset2FA = () => {
    const reset2FAObject = {
      username: userEmail,
    };
    axiosInstance
      .post("/users/reset-2fa", { data: reset2FAObject })
      .then((res) => {
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
        } else {
          setShowReset2FAModal(false);
          getUsers();
          Toast(res.message.description, "success");
        }
      })
      .catch((err) => {
        console.log("error", err);
        Toast(err.response.data.message.description, "error");
      });
  };

  const confirmAction = () => {
    axiosInstance
      .delete(`/users/${user_Id}`)
      .then((res) => {
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
        } else {
          // console.log(res);
          setShowConfirmationModal(false);
          getUsers();
          Toast("User deleted successfully!", "success");
        }
      })
      .catch((err) => {
        console.log("error", err);
        Toast("Error while deleting user!", "error");
      });
  };
  return (
    <>
      <ConfirmationModal
        title={"Reset Password"}
        message={`This will reset ${userName}'s password. Do you want to proceed?`}
        isShow={showPasswordModal}
        hideModal={() => setShowPasswordModal(false)}
        confirmAction={() => changePassword()}
      />
      <ConfirmationModal
        title={"Reset 2FA"}
        message={`This will reset ${userName}'s 2FA. Do you want to proceed?`}
        isShow={showReset2FAModal}
        hideModal={() => setShowReset2FAModal(false)}
        confirmAction={() => reset2FA()}
      />
      <ConfirmationModal
        title={"Delete User"}
        message={"Are you sure you want to delete this user ?"}
        isShow={showConfirmationModal}
        hideModal={() => setShowConfirmationModal(false)}
        confirmAction={() => confirmAction()}
      />
    </>
  );
};

export default ResetPasswordModal;
