import React, { useEffect, useState, useRef, useCallback } from "react";
import GenerateReportModal from "./GenerateReportModal";
import axiosInstance from "../../utils/axiosInstance";
import { baseUrl } from "../../configuration/apiUrl";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import Button from "../../components/Buttons/ButtonBasic";
import BootstrapTable from "react-bootstrap-table-next";
import CSVIcon from "../../assets/images/csv.webp";
import PDFIcon from "../../assets/images/pdf3.png";
import moment from "moment";
import { useDateTime } from "../../components/Helper/DateTime";
import Toast from "../../components/Toast/Toast";
import Pagination from "../../components/pagination/pagination";
import { refreshToken } from "../../utils/axiosInstance";
import BadgeRounded from "../../components/BadgeRounded/BadgeRounded";
import { DateFormat } from "../../utils/formatDateTime";

const UserReports = () => {
  const { date, isMobile, setRefresh } = useDateTime();
  const [showModal, setShowModal] = useState(false);
  const [reportsList, setReportsList] = useState([]);
  const [reportsListLoader, setReportsListLoader] = useState(true);
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(1, "weeks").format("YYYY-MM-DD")
  );
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [selectedReport, setSelectedReport] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [totalPages, setTotalPages] = useState(0);
  const [sort, setSort] = useState({ sort_by: "id", sort_order: "desc" });
  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);
  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);
  const linkRef = useRef(null);
  useEffect(() => {
    getReportsList();
  }, []);

  useEffect(() => {
    let timerId = setInterval(() => {
      if (selectedReport) {
        getAvailableReports(selectedReport, false);
      }
    }, 10000);
    return () => {
      clearInterval(timerId);
    };
  });

  const onSortTable = (type, newState) => {
    setPage(1);
    setSort({ sort_by: newState.sortField, sort_order: newState.sortOrder });
  };
  const getReportsList = async () => {
    try {
      setReportsListLoader(true);
      const response = await axiosInstance.get("/reports/user-reports");
      if (
        response.message.status !== "200" &&
        response.message.status !== "204"
      ) {
        Toast(response.message.description, "error");
        setReportsListLoader(false);
      } else {
        const filteredReports = response?.data?.filter(
          (item) => item?.reportType === "USER"
        );
        setReportsList(filteredReports || []);
        if (filteredReports && filteredReports.length > 0) {
          setSelectedReport(filteredReports[0].id);
          getAvailableReports(filteredReports[0].id);
          setReportsListLoader(false);
        } else {
          setData([]);
          setSelectedReport("");
          setReportsListLoader(false);
        }
      }
    } catch (error) {
      console.log(error);
      setReportsListLoader(false);
    }
  };

  const getAvailableReports = useCallback(
    async (id, loader) => {
      try {
        setReportsListLoader(loader === false ? false : true);
        const response = await axiosInstance.get(
          `/reports/${id}?page=${page - 1}&size=${pageSize}&sort_by=${
            sort.sort_by
          }&sort_order=${sort.sort_order}`
        );
        if (
          response.message.status !== "200" &&
          response.message.status !== "204"
        ) {
          Toast(response.message.description, "error");
          setReportsListLoader(false);
          setData([]);
        } else {
          setSelectedReport(id);
          setData(response.data.reports);
          setReportsListLoader(false);
          setPage(response.page.number + 1);
          setTotalPages(response.page.totalPages);
        }
      } catch (error) {
        console.log(error);
        setData([]);
        setReportsListLoader(false);
      }
    },
    [page, pageSize, sort]
  );

  const generateReport = async () => {
    try {
      setReportsListLoader(true);
      const response = await axiosInstance.post(`/reports/${selectedReport}`, {
        dateFrom: dateFrom,
        dateTo: dateTo,
      });
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
        setData([]);
        setReportsListLoader(false);
      } else {
        setData(response.data);
        getAvailableReports(selectedReport);
        setReportsListLoader(false);
      }
    } catch (error) {
      console.log(error);
      setData([]);
      setReportsListLoader(false);
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "fromDate",
      text: "Date From",
      formatter: (cell) => (cell ? DateFormat(date, cell) : "-"),
      sort: true,
    },
    {
      dataField: "toDate",
      text: "Date To",
      formatter: (cell) => (cell ? DateFormat(date, cell) : "-"),
      sort: true,
    },
    {
      dataField: "fileName",
      text: "Output File Name",
      sort: true,
    },
    {
      dataField: "reportStatus",
      text: "Status",
      formatter: (cell) =>
        cell ? (
          <BadgeRounded
            color={
              cell == "PROCESSED" ? "success" : cell == "ERROR" ? "danger" : ""
            }
            customStyle={{
              backgroundColor:
                cell !== "PROCESSED" && cell !== "ERROR" ? "#9fa6b2" : "",
            }}
            title={cell}
          />
        ) : (
          "-"
        ),
      sort: false,
    },
    {
      dataField: "downloadLink",
      text: "Action",
      formatter: (cell, row) => {
        const csvClassName = `right-btn mr-2 float-left${
          isDownloadingCSV[row.id] || row?.reportStatus !== "PROCESSED" ? " greyscale" : ""
        }`;
        const pdfClassName = `right-btn float-right${
          isDownloadingPDF[row.id] || row?.reportStatus !== "PROCESSED" ? " greyscale" : ""
        }`;
        return (
          <div style={{ width: "60px" }}>
            {row.csvFileAvailable ? (
              <img
                width={25}
                height={25}
                src={CSVIcon}
                className={csvClassName}
                alt="csv"
              />
            ) : null}
            {row.pdfFileAvailable ? (
              <img
                width={20}
                height={25}
                src={PDFIcon}
                className={pdfClassName}
                alt="pdf"
              />
            ) : null}
          </div>
        );
      },

      events: {
        onClick: async (e, column, columnIndex, row, rowIndex) => {
          if (e.target.alt === "pdf" && row?.reportStatus === "PROCESSED") {
            setIsDownloadingPDF((prevState) => ({
              ...prevState,
              [row.id]: true,
            }));
            setIsDownloadingCSV(false);
            var flag;
            do {
              const token = JSON.parse(
                localStorage.getItem("userData")
              ).accessToken;
              flag = false;
              try {
                const response = await fetch(
                  baseUrl + `/reports/${row.id}/download?file-type=pdf`,
                  {
                    headers: {
                      Authorization: "Bearer " + token,
                    },
                  }
                );
                if (response.status !== 401 && response.status !== 460) {
                  const blob = await response.blob();
                  const href = window.URL.createObjectURL(blob);
                  const a = linkRef.current;

                  a.href = href;
                  a.download = `${row.fileName}.pdf`;
                  a.click();
                  a.href = "";
                } else {
                  refreshToken();
                  flag = true;
                }
              } catch (error) {
                console.error(error);
              } finally {
                setIsDownloadingPDF(false);
              }
            } while (flag);
          }

          if (e.target.alt === "csv" && row?.reportStatus === "PROCESSED") {
            setIsDownloadingCSV((prevState) => ({
              ...prevState,
              [row.id]: true,
            }));
            setIsDownloadingPDF(false);
            var flag;
            do {
              const token = JSON.parse(
                localStorage.getItem("userData")
              ).accessToken;
              flag = false;
              try {
                const response = await fetch(
                  baseUrl + `/reports/${row.id}/download?file-type=csv`,
                  {
                    headers: {
                      Authorization: "Bearer " + token,
                    },
                  }
                );
                if (response.status !== 401 && response.status !== 460) {
                  const blob = await response.blob();
                  const href = window.URL.createObjectURL(blob);
                  const a = linkRef.current;

                  a.href = href;
                  a.download = `${row.fileName}.csv`;
                  a.click();
                  a.href = "";
                } else {
                  refreshToken();
                  flag = true;
                }
              } catch (error) {
                console.error(error);
              } finally {
                setIsDownloadingCSV(false);
              }
            } while (flag);
          }
        },
      },
    },
  ];

  useEffect(() => {
    if (selectedReport) {
      getAvailableReports(selectedReport);
    }
  }, [page, sort, pageSize, selectedReport, getAvailableReports]);

  useEffect(() => {
    setRefresh(() => () => {
      if (selectedReport) {
        getAvailableReports(selectedReport);
      }
    });
    return () => {
      setRefresh(() => () => {});
    };
  }, [getAvailableReports, selectedReport, setRefresh]);

  const handleReportClick = async (reportId) => {
    setSelectedReport(reportId);
    await getAvailableReports(reportId);
  };

  return (
    <div className="row">
      {reportsListLoader && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div
        style={{
          maxHeight: "none",
          height: !isMobile ? "85vh" : "",
          opacity: reportsListLoader ? "0.07" : "1",
        }}
        className="col-3 mt-0 info-tile value color-dark border-right-rounded"
      >
        {/* eslint-disable-next-line */}
        <a ref={linkRef} />
        <div
          className="row pt-3 fancy-scroll"
          style={{ overflowY: "auto", maxHeight: "100%" }}
        >
          {reportsList?.map((report) => (
            <div
              key={report.id}
              className={`reportList ${
                selectedReport === report.id ? "selected" : ""
              }`}
              onClick={() => handleReportClick(report.id)}
            >
              <span>{report.reportName}</span>
              <span>{">"}</span>
            </div>
          ))}
        </div>
      </div>
      <div
        style={{
          maxHeight: "none",
          height: "85vh",
          opacity: !reportsListLoader ? "1" : "0.07",
        }}
        className="col-9 mt-0 value color-dark border-right-rounded"
      >
        <div className="row">
          <div className="w-100 ml-5 d-flex justify-content-between align-items-center">
            <span style={{ fontSize: "18px" }} className=" value color-light">
              Available Reports
            </span>
            <div>
              <Button
                title="Generate Report"
                classes="color-dark btn-gen-report"
                onClick={() => setShowModal(true)}
              />
            </div>
          </div>
        </div>
        {data && data.length > 0 ? (
          <div className="row">
            <div className="col-md-12">
              <div className=" p-3">
                <BootstrapTable
                  columns={columns}
                  bootstrap4={true}
                  keyField="id"
                  bordered={false}
                  classes="rs-table table-layout-auto mb-0 table-custom-style"
                  wrapperClasses="overflow-y-auto fancy-scroll"
                  data={data}
                  onTableChange={onSortTable}
                  remote={{ sort: true }}
                />
                <Pagination
                  page={page}
                  setPage={setPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  totalPages={totalPages}
                />
              </div>
            </div>
          </div>
        ) : (
          <h3 className=" d-flex align-items-center justify-content-center h-100">
            No Reports Available
          </h3>
        )}
      </div>
      <GenerateReportModal
        showModal={showModal}
        setShowModal={setShowModal}
        dateFrom={dateFrom}
        setDateFrom={setDateFrom}
        dateTo={dateTo}
        setDateTo={setDateTo}
        generateReport={generateReport}
        setReportsListLoader={setReportsListLoader}
        setPage={setPage}
      />
    </div>
  );
};

export default UserReports;
