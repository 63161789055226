import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { baseUrl } from "../../configuration/apiUrl";
import "./Login.css";
import logo from "../../assets/images/logo-dark.png";
import DibLogo from "../../assets/images/logos/dibLogo.png";
import McbLogo from "../../assets/images/logos/mcb-logo.svg";
import RECaasLogo from "../../assets/images/logos/recaas-landing-page-logo-1.png";
import BasicButton from "../../components/Buttons/ButtonBasic";
import axiosInstance from "../../utils/axiosInstance";
import Toast from "../../components/Toast/Toast";
import withDateTime from "../../utils/timedatehoc";
import { Alert } from "react-bootstrap";

const Login = ({ dateTime }) => {
  const [loginErr, setLoginErr] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isTabActive, setIsTabActive] = useState(false);
  const [logoType, setLogoType] = useState(""); // State to store logoType from /context

  const history = useHistory();

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Email is required *"),
    password: Yup.string().required("Password is required *"),
  });

  // Handle user authentication
  const authenticationUser = async (values) => {
    try {
      setSpinner(true);
      let req = {
        data: {
          username: values.email,
          password: values.password,
        },
      };
      const apiResponse = await axios.post(baseUrl + "/users/login", req);
      const jsonResponse = apiResponse;
      const { userPreferences } = jsonResponse?.data?.data;
      const accessToken = jsonResponse?.data?.data?.accessToken;

      if (userPreferences?.length > 0) {
        userPreferences.forEach((pref) => {
          if (pref.prefKeys === "dateFormat") {
            dateTime.setDate(pref.prefValues);
          } else if (pref.prefKeys === "timeFormat") {
            dateTime.setTime(pref.prefValues);
          } else {
            dateTime.setDateTime(pref.prefValues);
          }
        });
      }

      if (jsonResponse.data.message.status === "200") {
        axiosInstance
          .get(`/roles/users`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then((response) => {
            if (response.message.status !== "200") {
              Toast(response.message.description, "error");
            } else {
              localStorage.setItem(
                "userData",
                JSON.stringify(jsonResponse?.data?.data)
              );
              localStorage.setItem("userRoles", JSON.stringify(response));
              history.push("/");
            }
          })
          .catch((error) => {
            setSpinner(false);
            setLoginErr(true);
            setLoginErrorMessage(error?.response?.data?.message?.description);
            console.log("User roles error", error);
          });
      } else {
        setLoginErr(true);
      }
    } catch (err) {
      setSpinner(false);
      setLoginErr(true);
      setLoginErrorMessage(err?.response?.data?.message?.description);
    }
  };

  // Handle tab visibility change
  const handleVisibilityChange = () => {
    setIsTabActive(window.document.visibilityState === "visible");
  };

  const fetchContextData = async () => {
    try {
      const response = await axios.get(baseUrl + `/context`);
      const fetchedLogoType = response?.data?.data?.landingPageLogo || "default";
      localStorage.setItem("landingPageLogo", fetchedLogoType);
      setLogoType(fetchedLogoType);
    } catch (error) {
      console.error("Error fetching context data:", error);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const serializedState = urlParams?.get("state");
    const message = JSON.parse(decodeURIComponent(serializedState));
    setErrorMessage(message);

    const accessToken = JSON.parse(
      localStorage.getItem("userData")
    )?.accessToken;
    if (accessToken) {
      history.push("/");
    }

    window.document.addEventListener(
      "visibilitychange",
      handleVisibilityChange
    );

    // Fetch context data when the component mounts
    fetchContextData();

    return () => {
      window.document.removeEventListener(
        "visibilitychange",
        handleVisibilityChange
      );
    };
  }, [history]);

  useEffect(() => {
    if (
      isTabActive &&
      JSON.parse(localStorage.getItem("userData"))?.accessToken &&
      JSON.parse(localStorage.getItem("userRoles"))?.data
    ) {
      history.push("/");
    }
  }, [isTabActive, history]);

  // Get the logoType from localStorage, fallback to the state
  const logoTypeFromLocalStorage =
    logoType || localStorage.getItem("landingPageLogo");

  return (
    <div className="maincontainer">
      <div className="container-fluid">
        <div className="row no-gutter">
          <div className="col-md-6 d-none d-md-flex align-items-center bg-image bg-light">
            {/* Logo Section */}
            <img
              src={logo}
              alt="Logo"
              width={"70%"}
              style={{ margin: "auto" }}
            />
          </div>

          <div className="col-md-6 bg-light">
            {logoTypeFromLocalStorage === "dib" && (
              <div className="d-flex">
                <div className="container">
                  <div className="row">
                    <div className="col d-flex justify-content-end">
                      <img
                        src={
                          logoTypeFromLocalStorage === "dib" ? RECaasLogo : ""
                        }
                        alt="Logo"
                        width={
                          logoTypeFromLocalStorage === "dib" ? "30%" : "70%"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="login d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-lg-10 col-xl-7 mx-auto">
                    {/* Show an Error Message */}
                    {errorMessage?.status === "451" && (
                      <Alert
                        variant="danger"
                        dismissible
                        onClose={() => setErrorMessage(null)}
                      >
                        {errorMessage?.description}
                      </Alert>
                    )}
                    {(logoTypeFromLocalStorage === "dib" ||
                      logoTypeFromLocalStorage === "MCB") && (
                      <img
                        src={
                          logoTypeFromLocalStorage === "dib"
                            ? DibLogo
                            : logoTypeFromLocalStorage === "MCB"
                            ? McbLogo
                            : logo
                        }
                        alt="Logo"
                        width={
                          logoTypeFromLocalStorage === "MCB"
                            ? "40%"
                            : logoTypeFromLocalStorage === "dib"
                            ? "50%"
                            : "70%"
                        }
                        style={{ margin: "auto" }}
                      />
                    )}

                    <h4 className="display-5 pt-5">Sign in</h4>
                    <p className="text-muted mb-4">
                      Login to access your account.
                    </p>

                    <Formik
                      initialValues={{ email: "", password: "" }}
                      validationSchema={loginSchema}
                      onSubmit={(values) => {
                        if (spinner) return;
                        authenticationUser(values);
                      }}
                    >
                      {({ errors, touched, handleSubmit, handleReset }) => (
                        <Form
                          id="add-notes"
                          className="form"
                          onSubmit={handleSubmit}
                          onReset={handleReset}
                        >
                          <div className="form-group">
                            <div className="mb-3">
                              <Field
                                className="form-control border-0 shadow-sm px-4"
                                type="email"
                                placeholder="Email address"
                                name="email"
                              />
                              {errors.email && touched.email && (
                                <div className="field-error">
                                  {errors.email}
                                </div>
                              )}
                            </div>
                            <div className="mb-3">
                              <Field
                                className="form-control border-0 shadow-sm px-4"
                                type="password"
                                placeholder="Enter Password"
                                name="password"
                              />
                              {errors.password && touched.password && (
                                <div className="field-error">
                                  {errors.password}
                                </div>
                              )}
                            </div>
                            <div>
                              <Link to="/forgot-password" id="forgotPassword">
                                <label
                                  htmlFor="customCheck1"
                                  className="form-check-label text-secondary text-underline cursor-pointer pl-2"
                                >
                                  Forgot Password?
                                </label>
                              </Link>
                            </div>
                            <div className="d-grid gap-2 mt-4">
                              <BasicButton
                                type="submit"
                                name="submit"
                                title="Sign in"
                                className={"btn btn-info btn-sm"}
                                style={{
                                  width: "100%",
                                  borderRadius: "20px",
                                }}
                                spinner={spinner}
                                disabled={spinner}
                              />
                            </div>
                            {loginErr && (
                              <div
                                className="alert alert-danger mt-2"
                                role="alert"
                              >
                                {loginErrorMessage}
                              </div>
                            )}
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withDateTime(Login);
