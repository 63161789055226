import React, { useState } from "react";
import { Field } from "formik";
import { Collapse, FormCheck } from "react-bootstrap";
import Select from "react-select";
import AddIcon from "../../../../assets/images/add-icon.png";
import ButtonTransparent from "../../../../components/Buttons/ButtonTransparent";
import CustomAccordion from "../../../../components/Accordion/CustomAccordion";
import MatchingRuleModal from "./MatchingRuleModal";
import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";

const MatchingRule = ({
  index,
  el,
  dsaFields,
  dsbFields,
  operatorOptions,
  setFieldValue,
  values,
}) => {
  const [open, setOpen] = useState([]);
  const [showMatchingRuleModal, setShowMatchingRuleModal] = useState(false);
  const [editMatchingRule, setEditMatchingRule] = useState(null);
  const [matchingRuleObject, setMatchingRuleObject] = useState(null);
  const [showMatchingRuleDeleteModal, setShowMatchingRuleDeleteModal] =
    useState(false);
  const [matchingRuleDeleteIndex, setMatchingRuleDeleteIndex] = useState(null);
  return (
    <div>
      {" "}
      <>
        <div className="d-flex flex-wrap align-items-center">
          <div className="col-md-12">
            <div className="float-right">
              <ButtonTransparent
                title={"Add Field"}
                icon={AddIcon}
                onClick={() => {
                  setEditMatchingRule({
                    path: `pairExecution[${index}].matchingRule.fields[${
                      el?.matchingRule?.fields?.length > 0
                        ? el?.matchingRule?.fields?.length
                        : 0
                    }]`,
                    isNew: true,
                  });
                  setMatchingRuleObject({
                    ds_a_field_name: "",
                    ds_b_field_name: "",
                    operator: "",
                    label: "",
                  });
                  setShowMatchingRuleModal(true);
                }}
                border
              />
            </div>
          </div>
        </div>
        {el?.matchingRule &&
          el?.matchingRule?.fields?.map((item, index1) => (
            <CustomAccordion
              title={item?.label ?? ""}
              onRemove={() => {
                // remove current object
                setShowMatchingRuleDeleteModal(true);
                setMatchingRuleDeleteIndex(index1);
              }}
              onEdit={() => {
                setMatchingRuleObject(item);
                setEditMatchingRule({
                  path:
                    "pairExecution[" +
                    index +
                    "].matchingRule.fields[" +
                    index1 +
                    "]",
                  isNew: false,
                });
                setShowMatchingRuleModal(true);
              }}
              defaultActiveKey={false}
              showDragIcon={false}
              showEditIcon={true}
              showRemoveIcon={true}
            >
              <div className="p-3 my-3">
                <div className="row">
                  <fieldset disabled className="col-3">
                    {/* DropDown */}
                    <div className="workflowModalTitle">DSA Field Name</div>
                    <div title={item?.ds_a_field_name}>
                      <Select
                        options={dsaFields}
                        name={"ds A Field Name"}
                        value={dsaFields?.find(
                          (field) => field.value === item?.ds_a_field_name
                        )}
                        components={{ DropdownIndicator: null }}
                      />
                    </div>
                  </fieldset>
                  <fieldset disabled className="col-3">
                    <div className="workflowModalTitle">Operator</div>
                    <div
                      title={
                        operatorOptions?.matchingRuleOperators?.find(
                          (operator) =>
                            operator.value === item?.operator?.split("(")[0]
                        )?.label
                      }
                    >
                      <Select
                        options={operatorOptions?.matchingRuleOperators}
                        name={"Operator"}
                        value={operatorOptions?.matchingRuleOperators?.find(
                          (operator) =>
                            operator.value === item?.operator?.split("(")[0]
                        )}
                        components={{ DropdownIndicator: null }}
                      />
                    </div>
                  </fieldset>

                  {item?.operator?.includes("FUZZY") && (
                    <fieldset disabled className="col-2">
                      <div className="workflowModalTitle">Matching %</div>
                      <Field
                        title={
                          item?.operator?.match(/\(([^)]+)\)/)?.length > 0
                            ? item?.operator?.match(/\(([^)]+)\)/)[1]
                            : ""
                        }
                        className="form-control rs-input highlight"
                        type="number"
                        placeholder="Enter Matching %"
                        name="matching_percentage"
                        min="0"
                        max="100"
                        value={
                          item?.operator?.match(/\(([^)]+)\)/)?.length > 0
                            ? item?.operator?.match(/\(([^)]+)\)/)[1]
                            : ""
                        }
                      />
                    </fieldset>
                  )}

                  {item?.operator?.includes("REGEX") && (
                    <fieldset disabled className="col-2">
                      <div className="workflowModalTitle">Regex</div>
                      <Field
                        title={item?.regex}
                        className="form-control rs-input highlight"
                        placeholder="Regex"
                        name={`pairExecution[${index}].matchingRule.fields[${index1}].regex`}
                        value={item?.regex}
                      />
                    </fieldset>
                  )}

                  {item?.operator?.split("(")[0] === "IN" && (
                    <fieldset
                      disabled
                      className="col-2 d-flex"
                      style={{ paddingTop: "2rem" }}
                    >
                      <span className="mr-2 cursor-context-menu">{"A->B"}</span>
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitch1"
                          checked={
                            item?.operator
                              ? item?.operator
                                  .match(/IN\(([^,]+),([^,]+)\)/)[1]
                                  .trim() === "ds_a_field_name" &&
                                item?.operator
                                  .match(/IN\(([^,]+),([^,]+)\)/)[2]
                                  .trim() === "ds_b_field_name"
                                ? false
                                : true
                              : false
                          }
                        />
                        <label
                          className="custom-control-label"
                          for="customSwitch1"
                        ></label>
                      </div>
                      <span class="ml-2 cursor-context-menu">{"B->A"}</span>
                    </fieldset>
                  )}
                  {item?.operator?.split("(")[0] === "NIN" && (
                    <fieldset
                      disabled
                      className="col-2 d-flex"
                      style={{ paddingTop: "2rem" }}
                    >
                      <span className="mr-2 cursor-context-menu">{"A->B"}</span>
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitch1"
                          checked={
                            item?.operator
                              ? item?.operator
                                  .match(/NIN\(([^,]+),([^,]+)\)/)[1]
                                  .trim() === "ds_a_field_name" &&
                                item?.operator
                                  .match(/NIN\(([^,]+),([^,]+)\)/)[2]
                                  .trim() === "ds_b_field_name"
                                ? false
                                : true
                              : false
                          }
                        />
                        <label
                          className="custom-control-label"
                          for="customSwitch1"
                        ></label>
                      </div>
                      <span class="ml-2 cursor-context-menu">{"B->A"}</span>
                    </fieldset>
                  )}

                  <fieldset disabled className="col-3">
                    {/* DropDown */}
                    <div className="workflowModalTitle">DSB Field Name</div>
                    <div title={item?.ds_b_field_name}>
                      <Select
                        options={dsbFields}
                        name={"ds B Field Name"}
                        value={dsbFields?.find(
                          (field) => field.value === item?.ds_b_field_name
                        )}
                        components={{ DropdownIndicator: null }}
                      />
                    </div>
                  </fieldset>

                  {/* show/hide Advanced toggle for Matching Rule */}
                  <div className="col-1 d-flex align-items-center justify-content-around  pt-3">
                    <i
                      id={"matchingRule" + index1}
                      onClick={() => {
                        setOpen((prev) => {
                          return {
                            ...prev,
                            [index1]: !prev[index1],
                          };
                        });
                      }}
                      style={{ color: "#118ab2", cursor: "pointer" }}
                      className={
                        "fa" +
                        (open[index1] ? " fa-chevron-up" : " fa-chevron-down")
                      }
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                {/* Advanced Options for Matching Rule starts here*/}
                <Collapse className={`mt-3`} in={open[index1] || false}>
                  <fieldset disabled>
                    <div className="row mt-3">
                      <div className="col-3">
                        <div className="workflowModalTitle">Label</div>
                        <Field
                          title={item?.label}
                          className="form-control rs-input highlight"
                          type="text"
                          placeholder="Label"
                          name={`pairExecution[${index}].matchingRule.fields[${index1}].label`}
                        />
                      </div>
                    </div>
                    {/* DSA - FIELD FUNCTIONS STARTS */}
                    <div className="row mt-3">
                      <div className="col-3">
                        <div className="workflowModalTitle">
                          DSA Field Function
                        </div>
                        <div className="form-check py-2">
                          <FormCheck
                            type="switch"
                            name="dsaFieldFunction"
                            style={{
                              zIndex: 0,
                            }}
                            checked={item?.hasOwnProperty("ds_a_field_func")}
                          />
                        </div>
                      </div>
                      {/* FOR DSA - VAR function */}
                      {item?.hasOwnProperty("ds_a_field_func") && (
                        <>
                          <div className="col-3">
                            <div className="workflowModalTitle">Function</div>
                            <div
                              title={
                                operatorOptions?.matchingRuleFunctions?.find(
                                  (option) =>
                                    option.value ===
                                    item?.ds_a_field_func?.split("(")[0]
                                )?.label
                              }
                            >
                              <Select
                                options={operatorOptions?.matchingRuleFunctions}
                                name={"ds A Field Function"}
                                value={
                                  operatorOptions?.matchingRuleFunctions?.find(
                                    (option) =>
                                      option.value ===
                                      item?.ds_a_field_func?.split("(")[0]
                                  ) || {}
                                }
                                components={{ DropdownIndicator: null }}
                              />
                            </div>
                          </div>
                          {item?.ds_a_field_func?.includes("VAR") && (
                            <>
                              <div className="col-2">
                                <div className="workflowModalTitle">
                                  Minimum
                                </div>
                                <div className="input-icon-wrapper">
                                  <span className="input-icon">-</span>
                                  <Field
                                    title={
                                      item?.ds_a_field_func &&
                                      (/-([\d.]+)%/.test(item.ds_a_field_func)
                                        ? item.ds_a_field_func?.match(
                                            /-([\d.]+)%/
                                          )[1]
                                        : /(?<=-)[\d.]+/.test(
                                            item.ds_a_field_func
                                          )
                                        ? item.ds_a_field_func?.match(
                                            /(?<=-)[\d.]+/
                                          )[0]
                                        : "-")
                                    }
                                    className="form-control rs-input highlight with-icon"
                                    type="number"
                                    placeholder="Minimum"
                                    name="Minimum"
                                    value={
                                      item?.ds_a_field_func &&
                                      (/-([\d.]+)%/.test(item.ds_a_field_func)
                                        ? item.ds_a_field_func?.match(
                                            /-([\d.]+)%/
                                          )[1]
                                        : /(?<=-)[\d.]+/.test(
                                            item.ds_a_field_func
                                          )
                                        ? item.ds_a_field_func?.match(
                                            /(?<=-)[\d.]+/
                                          )[0]
                                        : "-")
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-2">
                                <div className="workflowModalTitle">
                                  Maximum
                                </div>
                                <div className="input-icon-wrapper">
                                  <span className="input-icon">+</span>
                                  <Field
                                    title={
                                      item?.ds_a_field_func &&
                                      (/\+([\d.]+)%/.test(item.ds_a_field_func)
                                        ? item.ds_a_field_func?.match(
                                            /\+([\d.]+)%/
                                          )[1]
                                        : /(?<=\+)[\d.]+/.test(
                                            item.ds_a_field_func
                                          )
                                        ? item.ds_a_field_func?.match(
                                            /(?<=\+)[\d.]+/
                                          )[0]
                                        : "+")
                                    }
                                    className="form-control rs-input highlight with-icon"
                                    type="number"
                                    placeholder="Maximum"
                                    name="Maximum"
                                    value={
                                      item?.ds_a_field_func &&
                                      (/\+([\d.]+)%/.test(item.ds_a_field_func)
                                        ? item.ds_a_field_func?.match(
                                            /\+([\d.]+)%/
                                          )[1]
                                        : /(?<=\+)[\d.]+/.test(
                                            item.ds_a_field_func
                                          )
                                        ? item.ds_a_field_func?.match(
                                            /(?<=\+)[\d.]+/
                                          )[0]
                                        : "+")
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          )}

                          {item?.ds_a_field_func?.includes("VAR") && (
                            <div className="col-2">
                              <div className="workflowModalTitle">
                                Variance %
                              </div>
                              <div className="form-check py-2">
                                <FormCheck
                                  type="switch"
                                  checked={/%\s*\)/.test(item.ds_a_field_func)}
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      {/* FOR DSA - LEFT function */}
                      {item?.ds_a_field_func?.includes("LEFT") && (
                        <>
                          <div className="col-2">
                            <div className="workflowModalTitle">
                              No. of Characters
                            </div>
                            <Field
                              title={
                                item?.ds_a_field_func &&
                                /(?<=\().*?(?=\))/.test(item.ds_a_field_func)
                                  ? item.ds_a_field_func?.match(
                                      /(?<=\().*?(?=\))/
                                    )[0]
                                  : ""
                              }
                              className="form-control rs-input highlight"
                              type="number"
                              placeholder="No. of Characters"
                              name="NumOfLeftCharacters"
                              value={
                                item?.ds_a_field_func &&
                                /(?<=\().*?(?=\))/.test(item.ds_a_field_func)
                                  ? item.ds_a_field_func?.match(
                                      /(?<=\().*?(?=\))/
                                    )[0]
                                  : ""
                              }
                            />
                          </div>
                        </>
                      )}

                      {/* FOR DSA `RIGHT` function */}
                      {item?.ds_a_field_func?.includes("RIGHT") && (
                        <>
                          <div className="col-2">
                            <div className="workflowModalTitle">
                              No. of Characters
                            </div>
                            <Field
                              title={
                                item?.ds_a_field_func &&
                                /(?<=\().*?(?=\))/.test(item.ds_a_field_func)
                                  ? item.ds_a_field_func?.match(
                                      /(?<=\().*?(?=\))/
                                    )[0]
                                  : ""
                              }
                              className="form-control rs-input highlight"
                              type="number"
                              placeholder="No. of Characters"
                              name="NumOfRightCharacters"
                              value={
                                item?.ds_a_field_func &&
                                /(?<=\().*?(?=\))/.test(item.ds_a_field_func)
                                  ? item.ds_a_field_func?.match(
                                      /(?<=\().*?(?=\))/
                                    )[0]
                                  : ""
                              }
                            />
                          </div>
                        </>
                      )}

                      {/* FOR DSA `SUBSTR` FUNCTION */}
                      {item?.ds_a_field_func?.includes("SUBSTR") && (
                        <>
                          <div className="col-2">
                            <div className="workflowModalTitle">
                              Start Character
                            </div>
                            <Field
                              title={
                                item?.ds_a_field_func &&
                                /(?<=\().*?(?=:)/.test(item.ds_a_field_func)
                                  ? item.ds_a_field_func?.match(
                                      /(?<=\().*?(?=:)/
                                    )[0]
                                  : ""
                              }
                              className="form-control rs-input highlight"
                              type="number"
                              placeholder="Start Character"
                              name="startCharacter"
                              value={
                                item?.ds_a_field_func &&
                                /(?<=\().*?(?=:)/.test(item.ds_a_field_func)
                                  ? item.ds_a_field_func?.match(
                                      /(?<=\().*?(?=:)/
                                    )[0]
                                  : ""
                              }
                            />
                          </div>

                          <div className="col-2">
                            <div className="workflowModalTitle">
                              End Character
                            </div>
                            <Field
                              title={
                                item?.ds_a_field_func &&
                                /(?<=:).*?(?=\))/.test(item.ds_a_field_func)
                                  ? item.ds_a_field_func?.match(
                                      /(?<=:).*?(?=\))/
                                    )[0]
                                  : ""
                              }
                              className="form-control rs-input highlight"
                              type="number"
                              placeholder="End Character"
                              name="endCharacter"
                              value={
                                item?.ds_a_field_func &&
                                /(?<=:).*?(?=\))/.test(item.ds_a_field_func)
                                  ? item.ds_a_field_func?.match(
                                      /(?<=:).*?(?=\))/
                                    )[0]
                                  : ""
                              }
                            />
                          </div>
                        </>
                      )}

                      {/* FOR DSA `REMOVE-SPECIAL-CHAR` FUNCTION */}
                      {item?.ds_a_field_func?.includes(
                        "REMOVE-SPECIAL-CHAR"
                      ) && (
                        <>
                          <div className="col-3">
                            <div className="workflowModalTitle">Regex</div>
                            <Field
                              title={
                                item?.ds_a_field_func?.startsWith(
                                  "REMOVE-SPECIAL-CHAR("
                                ) && item?.ds_a_field_func?.endsWith(")")
                                  ? item.ds_a_field_func?.slice(20, -1)
                                  : ""
                              }
                              className="form-control rs-input highlight"
                              type="text"
                              placeholder="Regex"
                              name="regex"
                              value={
                                item?.ds_a_field_func?.startsWith(
                                  "REMOVE-SPECIAL-CHAR("
                                ) && item?.ds_a_field_func?.endsWith(")")
                                  ? item.ds_a_field_func?.slice(20, -1)
                                  : ""
                              }
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {/* DSA - FIELD FUNCTIONS ENDS  */}

                    {/* DSB - FIELD FUNCTIONS STARTS */}
                    <div className="row mt-3">
                      <div className="col-3">
                        <div className="workflowModalTitle">
                          DSB Field Function
                        </div>
                        <div className="form-check py-2">
                          <FormCheck
                            type="switch"
                            name="dsbFieldFunction"
                            style={{
                              zIndex: 0,
                            }}
                            checked={item?.hasOwnProperty("ds_b_field_func")}
                          />
                        </div>
                      </div>
                      {/* FOR DSB - VAR function */}
                      {item?.hasOwnProperty("ds_b_field_func") && (
                        <>
                          <div className="col-3">
                            <div className="workflowModalTitle">Function</div>
                            <div
                              title={
                                operatorOptions?.matchingRuleFunctions?.find(
                                  (option) =>
                                    option.value ===
                                    item?.ds_b_field_func?.split("(")[0]
                                )?.label
                              }
                            >
                              <Select
                                options={operatorOptions?.matchingRuleFunctions}
                                name={"ds B Field Function"}
                                value={
                                  operatorOptions?.matchingRuleFunctions?.find(
                                    (option) =>
                                      option.value ===
                                      item?.ds_b_field_func?.split("(")[0]
                                  ) || {}
                                }
                                components={{ DropdownIndicator: null }}
                              />
                            </div>
                          </div>
                          {item?.ds_b_field_func?.includes("VAR") && (
                            <>
                              <div className="col-2">
                                <div className="workflowModalTitle">
                                  Minimum
                                </div>
                                <div className="input-icon-wrapper">
                                  <span className="input-icon">-</span>
                                  <Field
                                    title={
                                      item?.ds_b_field_func &&
                                      (/-([\d.]+)%/.test(item.ds_b_field_func)
                                        ? item.ds_b_field_func?.match(
                                            /-([\d.]+)%/
                                          )[1]
                                        : /(?<=-)[\d.]+/.test(
                                            item.ds_b_field_func
                                          )
                                        ? item.ds_b_field_func?.match(
                                            /(?<=-)[\d.]+/
                                          )[0]
                                        : "-")
                                    }
                                    className="form-control rs-input highlight with-icon"
                                    type="number"
                                    placeholder="Minimum"
                                    name="Minimum"
                                    value={
                                      item?.ds_b_field_func &&
                                      (/-([\d.]+)%/.test(item.ds_b_field_func)
                                        ? item.ds_b_field_func?.match(
                                            /-([\d.]+)%/
                                          )[1]
                                        : /(?<=-)[\d.]+/.test(
                                            item.ds_b_field_func
                                          )
                                        ? item.ds_b_field_func?.match(
                                            /(?<=-)[\d.]+/
                                          )[0]
                                        : "-")
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-2">
                                <div className="workflowModalTitle">
                                  Maximum
                                </div>
                                <div className="input-icon-wrapper">
                                  <span className="input-icon">+</span>
                                  <Field
                                    title={
                                      item?.ds_b_field_func &&
                                      (/\+([\d.]+)%/.test(item.ds_b_field_func)
                                        ? item.ds_b_field_func?.match(
                                            /\+([\d.]+)%/
                                          )[1]
                                        : /(?<=\+)[\d.]+/.test(
                                            item.ds_b_field_func
                                          )
                                        ? item.ds_b_field_func?.match(
                                            /(?<=\+)[\d.]+/
                                          )[0]
                                        : "+")
                                    }
                                    className="form-control rs-input highlight with-icon"
                                    type="number"
                                    placeholder="Maximum"
                                    name="Maximum"
                                    value={
                                      item?.ds_b_field_func &&
                                      (/\+([\d.]+)%/.test(item.ds_b_field_func)
                                        ? item.ds_b_field_func?.match(
                                            /\+([\d.]+)%/
                                          )[1]
                                        : /(?<=\+)[\d.]+/.test(
                                            item.ds_b_field_func
                                          )
                                        ? item.ds_b_field_func?.match(
                                            /(?<=\+)[\d.]+/
                                          )[0]
                                        : "+")
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          )}

                          {item?.ds_b_field_func?.includes("VAR") && (
                            <div className="col-2">
                              <div className="workflowModalTitle">
                                Variance %
                              </div>
                              <div className="form-check py-2">
                                <FormCheck
                                  type="switch"
                                  checked={/%\s*\)/.test(item.ds_b_field_func)}
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      {/* FOR DSA - LEFT function */}
                      {item?.ds_b_field_func?.includes("LEFT") && (
                        <>
                          <div className="col-2">
                            <div className="workflowModalTitle">
                              No. of Characters
                            </div>
                            <Field
                              title={
                                item?.ds_b_field_func &&
                                /(?<=\().*?(?=\))/.test(item.ds_b_field_func)
                                  ? item.ds_b_field_func?.match(
                                      /(?<=\().*?(?=\))/
                                    )[0]
                                  : ""
                              }
                              className="form-control rs-input highlight"
                              type="number"
                              placeholder="No. of Characters"
                              name="NumOfLeftCharacters"
                              value={
                                item?.ds_b_field_func &&
                                /(?<=\().*?(?=\))/.test(item.ds_b_field_func)
                                  ? item.ds_b_field_func?.match(
                                      /(?<=\().*?(?=\))/
                                    )[0]
                                  : ""
                              }
                            />
                          </div>
                        </>
                      )}

                      {/* FOR DSA `RIGHT` function */}
                      {item?.ds_b_field_func?.includes("RIGHT") && (
                        <>
                          <div className="col-2">
                            <div className="workflowModalTitle">
                              No. of Characters
                            </div>
                            <Field
                              title={
                                item?.ds_b_field_func &&
                                /(?<=\().*?(?=\))/.test(item.ds_b_field_func)
                                  ? item.ds_b_field_func?.match(
                                      /(?<=\().*?(?=\))/
                                    )[0]
                                  : ""
                              }
                              className="form-control rs-input highlight"
                              type="number"
                              placeholder="No. of Characters"
                              name="NumOfRightCharacters"
                              value={
                                item?.ds_b_field_func &&
                                /(?<=\().*?(?=\))/.test(item.ds_b_field_func)
                                  ? item.ds_b_field_func?.match(
                                      /(?<=\().*?(?=\))/
                                    )[0]
                                  : ""
                              }
                            />
                          </div>
                        </>
                      )}

                      {/* FOR DSA `SUBSTR` FUNCTION */}
                      {item?.ds_b_field_func?.includes("SUBSTR") && (
                        <>
                          <div className="col-2">
                            <div className="workflowModalTitle">
                              Start Character
                            </div>
                            <Field
                              title={
                                item?.ds_b_field_func &&
                                /(?<=\().*?(?=:)/.test(item.ds_b_field_func)
                                  ? item.ds_b_field_func?.match(
                                      /(?<=\().*?(?=:)/
                                    )[0]
                                  : ""
                              }
                              className="form-control rs-input highlight"
                              type="number"
                              placeholder="Start Character"
                              name="startCharacter"
                              value={
                                item?.ds_b_field_func &&
                                /(?<=\().*?(?=:)/?.test(item.ds_b_field_func)
                                  ? item.ds_b_field_func?.match(
                                      /(?<=\().*?(?=:)/
                                    )[0]
                                  : ""
                              }
                            />
                          </div>

                          <div className="col-2">
                            <div className="workflowModalTitle">
                              End Character
                            </div>
                            <Field
                              title={
                                item?.ds_b_field_func &&
                                /(?<=:).*?(?=\))/.test(item.ds_b_field_func)
                                  ? item.ds_b_field_func?.match(
                                      /(?<=:).*?(?=\))/
                                    )[0]
                                  : ""
                              }
                              className="form-control rs-input highlight"
                              type="number"
                              placeholder="End Character"
                              name="endCharacter"
                              value={
                                item?.ds_b_field_func &&
                                /(?<=:).*?(?=\))/.test(item.ds_b_field_func)
                                  ? item.ds_b_field_func?.match(
                                      /(?<=:).*?(?=\))/
                                    )[0]
                                  : ""
                              }
                            />
                          </div>
                        </>
                      )}

                      {/* FOR DSB `REMOVE-SPECIAL-CHAR` FUNCTION */}
                      {item?.ds_b_field_func?.includes(
                        "REMOVE-SPECIAL-CHAR"
                      ) && (
                        <>
                          <div className="col-3">
                            <div className="workflowModalTitle">Regex</div>
                            <Field
                              title={
                                item?.ds_b_field_func?.startsWith(
                                  "REMOVE-SPECIAL-CHAR("
                                ) && item?.ds_b_field_func?.endsWith(")")
                                  ? item.ds_b_field_func?.slice(20, -1)
                                  : ""
                              }
                              className="form-control rs-input highlight"
                              type="text"
                              placeholder="Regex"
                              name="regex"
                              value={
                                item?.ds_b_field_func?.startsWith(
                                  "REMOVE-SPECIAL-CHAR("
                                ) && item?.ds_b_field_func?.endsWith(")")
                                  ? item.ds_b_field_func?.slice(20, -1)
                                  : ""
                              }
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {/* DSB FIELD FUNCTIONS ENDS  */}
                  </fieldset>
                </Collapse>
              </div>
            </CustomAccordion>
          ))}
      </>
      {showMatchingRuleModal ? (
        <MatchingRuleModal
          show={showMatchingRuleModal}
          editObject={editMatchingRule}
          onHide={() => {
            setShowMatchingRuleModal(false);
            setMatchingRuleObject(null);
            setEditMatchingRule({ isNew: false, path: null });
          }}
          handleSubmit={(path, values) => setFieldValue(path, values)}
          dsaFields={dsaFields}
          dsbFields={dsbFields}
          values={matchingRuleObject}
          operatorOptions={operatorOptions}
        />
      ) : null}
      <ConfirmationModal
        isShow={showMatchingRuleDeleteModal}
        title={`Delete Matching Rule Field`}
        message={`Are you sure you want to delete this Matching Rule Field?`}
        hideModal={() => {
          setShowMatchingRuleDeleteModal(false);
        }}
        confirmAction={() => {
          // remove current object
          el?.matchingRule?.fields?.splice(matchingRuleDeleteIndex, 1);
          setFieldValue(
            `pairExecution[${index}].matchingRule.fields`,
            el?.matchingRule?.fields
          );
          setMatchingRuleDeleteIndex(null);
          setShowMatchingRuleDeleteModal(false);
        }}
      />
    </div>
  );
};

export default MatchingRule;
