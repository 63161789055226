import { Field } from "formik";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Collapse,
  FormCheck,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
import AddIcon from "../../../../assets/images/add-icon.png";
import ButtonTransparent from "../../../../components/Buttons/ButtonTransparent";
import CustomAccordion from "../../../../components/Accordion/CustomAccordion";
import AggregateModal from "./AggregateModal";
import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";
import DSBModal from "./DSBModal";

export const DSB = ({
  values,
  setFieldValue,
  operatorOptions,
  dsaFields,
  dsbFields,
  aggregateFields,
  setAggregateFields,
}) => {
  const [open, setOpen] = useState([]);
  const [activeKey, setActiveKey] = useState(false);
  const [activeMultiKey, setActiveMultiKey] = useState([]);
  const [showAggregateModal, setShowAggregateModal] = useState(false);
  const [aggregateObject, setAggregateObject] = useState(null);
  const [editAggregateObject, setEditAggregateObject] = useState(null);
  const [showAggregateDeleteModal, setShowAggregateDeleteModal] =
    useState(false);
  const [aggregateDeleteIndex, setAggregateDeleteIndex] = useState(null);
  const [showDSBModal, setShowDSBModal] = useState(false);
  const [dsbObject, setDsbObject] = useState(null);
  const [editDSBObject, setEditDSBObject] = useState(null);
  const [showDSBDeleteModal, setShowDSBDeleteModal] = useState(false);
  const [dsbDeleteIndex, setDSBDeleteIndex] = useState(null);
  useEffect(() => {
    setOpen([]);
    setActiveKey(false);
    setActiveMultiKey([]);
  }, [values?.id]);
  return (
    <div>
      <div className="d-flex justify-content-end align-items-center mt-3">
        {" "}
        {/* Add Button to Add DSA Fetch Criteria Fields */}
        <ButtonTransparent
          id="addDSBField"
          title={"Add Field"}
          icon={AddIcon}
          onClick={() => {
            setEditDSBObject({
              path: `toFetchCriteria.fields[${
                values?.toFetchCriteria?.fields?.length > 0
                  ? values?.toFetchCriteria?.fields?.length
                  : 0
              }]`,
              isNew: true,
            });
            setDsbObject([
              {
                label: "",
                ds_a_field_name: "",
                ds_b_field_name: "",
                operator: "",
              },
            ]);
            setShowDSBModal(true);
          }}
          border
        />
      </div>
      <Accordion className="pt-3">
        <Card>
          <Card.Header className={"background-secondary"}>
            <Accordion.Toggle
              id="and-operations-collapse"
              as={Button}
              variant="link"
              eventKey={activeKey}
              onClick={() => setActiveKey(!activeKey)}
              className="p-0"
              style={{
                textDecoration: "none",
                boxShadow: "none",
                color: "#1196c2",
              }}
            >
              {activeKey ? (
                <i className="fa fa-minus-circle" aria-hidden="true"></i>
              ) : (
                <i className="fa fa-plus-circle" aria-hidden="true"></i>
              )}
              {"  "}
              And Operations
            </Accordion.Toggle>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {"All given fields must match to fetch corresponding record"}
                </Tooltip>
              }
            >
              <Button
                className="float-right p-0"
                style={{
                  textDecoration: "none",
                  boxShadow: "none",
                  color: "#1196c2",
                }}
                variant="link"
              >
                <i className="fa fa-info-circle" aria-hidden="true"></i>
              </Button>
            </OverlayTrigger>
          </Card.Header>
          <Accordion.Collapse eventKey={activeKey} in={activeKey}>
            <Card.Body className={"px-4 pb-4 background-primary"}>
              {values?.toFetchCriteria?.fields?.filter(
                (row) => !row?.hasOwnProperty("OR")
              )?.length > 0 ? (
                values?.toFetchCriteria?.fields?.map(
                  (row, index) =>
                    !row?.hasOwnProperty("OR") && (
                      <CustomAccordion
                        id={"and-operations-" + index}
                        key={index}
                        title={row?.label ?? ""}
                        onRemove={() => {
                          // remove current object
                          setShowDSBDeleteModal(true);
                          setDSBDeleteIndex(index);
                        }}
                        onEdit={() => {
                          setDsbObject([row]);
                          setEditDSBObject({
                            path: "toFetchCriteria.fields[" + index + "]",
                            isNew: false,
                          });
                          setShowDSBModal(true);
                        }}
                        defaultActiveKey={false}
                        showDragIcon={false}
                        showEditIcon={true}
                      >
                        <div className="p-3 my-3">
                          {/* OR Operation Object Structure Start From Here */}
                          <div className="row">
                            <fieldset disabled className="col-3">
                              {/* DS A Field Name DropDown For DSB Fetch Criteria */}
                              <div className="workflowModalTitle">
                                DSA Field Name
                              </div>
                              <div title={row?.ds_a_field_name}>
                                <Select
                                  options={dsaFields}
                                  name={"ds A Field Name"}
                                  value={dsaFields?.find(
                                    (field) =>
                                      field.value === row?.ds_a_field_name
                                  )}
                                  components={{ DropdownIndicator: null }}
                                />
                              </div>
                            </fieldset>

                            <fieldset disabled className="col-3">
                              {/* Operator DropDown For DSB Fetch Criteria  */}
                              <div className="workflowModalTitle">Operator</div>
                              <div
                                title={
                                  operatorOptions?.rightCriteriaOperators?.find(
                                    (operator) =>
                                      operator.value === row?.operator
                                  )?.label
                                }
                              >
                                <Select
                                  options={
                                    operatorOptions?.rightCriteriaOperators
                                  }
                                  name={"Operator"}
                                  value={operatorOptions?.rightCriteriaOperators?.find(
                                    (operator) =>
                                      operator.value === row?.operator
                                  )}
                                  components={{ DropdownIndicator: null }}
                                />
                              </div>
                            </fieldset>

                            <fieldset disabled className="col-3">
                              {/* DS B Field Name DropDown For DSB Fetch Criteria  */}
                              <div className="workflowModalTitle">
                                DSB Field Name
                              </div>
                              <div title={row?.ds_b_field_name}>
                                <Select
                                  options={dsbFields}
                                  name={"ds B Field Name"}
                                  value={dsbFields?.find(
                                    (field) =>
                                      field.value === row?.ds_b_field_name
                                  )}
                                  components={{ DropdownIndicator: null }}
                                />
                              </div>
                            </fieldset>
                            {/* Show Tolerance Days when operator is BTW */}
                            {row?.operator === "BTW" && (
                              <fieldset disabled className="col-1">
                                <div className="workflowModalTitle">
                                  Min Tol
                                </div>
                                <Field
                                  className="form-control rs-input highlight"
                                  type="number"
                                  name={`toFetchCriteria.fields[${index}].tolerance_days[0]`}
                                  onChange={(e) => {
                                    if (Number(e?.target?.value) > 0) {
                                      return;
                                    }
                                    setFieldValue(
                                      `toFetchCriteria.fields[${index}].tolerance_days[0]`,
                                      e?.target?.value
                                    );
                                  }}
                                  style={{ width: "70px" }}
                                />
                              </fieldset>
                            )}
                            {row?.operator === "BTW" && (
                              <fieldset disabled className="col-1">
                                <div className="workflowModalTitle">
                                  Max Tol
                                </div>
                                <Field
                                  className="form-control rs-input highlight"
                                  type="number"
                                  name={`toFetchCriteria.fields[${index}].tolerance_days[1]`}
                                  onChange={(e) => {
                                    if (Number(e?.target?.value) < 0) {
                                      return;
                                    }
                                    setFieldValue(
                                      `toFetchCriteria.fields[${index}].tolerance_days[1]`,
                                      e?.target?.value
                                    );
                                  }}
                                  style={{ width: "70px" }}
                                />
                              </fieldset>
                            )}
                            {/* Tolerance Days Ends Here */}

                            {/* Show and Hide Icon */}
                            <div className="col-1 d-flex align-items-center justify-content-around pt-3">
                              <i
                                id={"DSB_Toggle" + index}
                                onClick={() => {
                                  setOpen((prev) => {
                                    return {
                                      ...prev,
                                      [index]: !prev[index],
                                    };
                                  });
                                }}
                                style={{
                                  color: "#118ab2",
                                  cursor: "pointer",
                                }}
                                className={
                                  "fa" +
                                  (open[index]
                                    ? " fa-chevron-up"
                                    : " fa-chevron-down")
                                }
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                          <Collapse
                            className="mt-3"
                            id={"DSB/ANDUpdate" + index}
                            in={open[index] || false}
                          >
                            <fieldset disabled className="row">
                              <div className="col-3">
                                {/* Label Text Field For DSB Fetch Criteria  */}
                                <div className="workflowModalTitle">Label</div>
                                <Field
                                  title={row?.label}
                                  className="form-control rs-input highlight"
                                  type="text"
                                  placeholder="Label"
                                  name={`toFetchCriteria.fields[${index}].label`}
                                  onChange={(e) =>
                                    setFieldValue(
                                      `toFetchCriteria.fields[${index}].label`,
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              {row?.hasOwnProperty("ds_b_field_value") && (
                                <div className="col-3">
                                  {/* DS B Field Value Text Field For DSB Fetch Criteria */}
                                  <div className="workflowModalTitle">
                                    DSB Field Value
                                  </div>
                                  <Field
                                    title={row?.ds_b_field_value}
                                    className="form-control rs-input highlight"
                                    type="text"
                                    placeholder="DSB Field Value"
                                    name={`toFetchCriteria.fields[${index}].ds_b_field_value`}
                                    onChange={(e) =>
                                      setFieldValue(
                                        `toFetchCriteria.fields[${index}].ds_b_field_value`,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              )}
                              <div className="col-3">
                                {/* Provide Field Value Switch */}
                                <div className="workflowModalTitle">
                                  Provide Field Value
                                </div>
                                <div className="form-check py-2">
                                  <FormCheck
                                    type="switch"
                                    name="provideFieldValue"
                                    style={{ zIndex: 0 }}
                                    checked={row?.hasOwnProperty(
                                      "ds_b_field_value"
                                    )}
                                    onChange={(e) => {
                                      // If checked then show ds_b_field_value
                                      if (e.target.checked) {
                                        setFieldValue(
                                          `toFetchCriteria.fields[${index}]`,
                                          {
                                            ...values.toFetchCriteria.fields[
                                              index
                                            ],
                                            ds_b_field_value: "",
                                          }
                                        );
                                      } else {
                                        //remove  ds_b_field_value from object
                                        const {
                                          ds_b_field_value,
                                          ...updatedField
                                        } =
                                          values.toFetchCriteria.fields[index];
                                        // Update the state with the new object
                                        setFieldValue(
                                          `toFetchCriteria.fields[${index}]`,
                                          updatedField
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              {/* Check Account Relationship */}
                              {row?.hasOwnProperty("check_relationship") && (
                                <div className="col-3">
                                  <div className="workflowModalTitle">
                                    Check Account Relationship
                                  </div>
                                  <div className="form-check py-2">
                                    <FormCheck
                                      type="switch"
                                      name="check_relationship"
                                      style={{ zIndex: 0 }}
                                      checked={
                                        values.toFetchCriteria.fields[index]
                                          ?.check_relationship
                                      }
                                      onChange={(e) =>
                                        setFieldValue(
                                          `toFetchCriteria.fields[${index}].check_relationship`,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </fieldset>
                          </Collapse>
                        </div>
                      </CustomAccordion>
                    )
                )
              ) : (
                <>
                  <div className="text-center mt-3">
                    No And Operations Found
                  </div>
                </>
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      {values?.toFetchCriteria?.fields?.map(
        (row, index) =>
          row?.hasOwnProperty("OR") && (
            <Accordion className="pt-3">
              <Card>
                <Card.Header className={"background-secondary"}>
                  <Accordion.Toggle
                    id={"or-operations-collapse" + index}
                    as={Button}
                    variant="link"
                    eventKey={activeMultiKey}
                    onClick={() =>
                      setActiveMultiKey((prev) => {
                        return {
                          ...prev,
                          [index]: !prev[index],
                        };
                      })
                    }
                    className="p-0"
                    style={{
                      textDecoration: "none",
                      boxShadow: "none",
                      color: "#1196c2",
                    }}
                  >
                    {activeMultiKey[index] ? (
                      <i className="fa fa-minus-circle" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    )}
                    {"  "}
                    OR Operations
                  </Accordion.Toggle>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        {
                          "At least one given field must match to fetch corresponding record"
                        }
                      </Tooltip>
                    }
                  >
                    <Button
                      className="float-right p-0"
                      style={{
                        textDecoration: "none",
                        boxShadow: "none",
                        color: "#1196c2",
                      }}
                      variant="link"
                    >
                      <i className="fa fa-info-circle" aria-hidden="true"></i>
                    </Button>
                  </OverlayTrigger>
                  <Button
                    id={"deleteRow" + index}
                    className="float-right p-0 mr-2"
                    style={{
                      textDecoration: "none",
                      boxShadow: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // remove current object
                      setShowDSBDeleteModal(true);
                      setDSBDeleteIndex(index);
                    }}
                    variant="link"
                  >
                    <i
                      className="fa fa-trash"
                      style={{
                        color: "#ff0000",
                      }}
                      aria-hidden="true"
                    ></i>
                  </Button>
                  <Button
                    id={"editRow" + index}
                    className="float-right p-0 mr-2"
                    style={{
                      textDecoration: "none",
                      boxShadow: "none",
                      color: "#1196c2",
                    }}
                    onClick={() => {
                      setDsbObject(row);
                      setEditDSBObject({
                        path: "toFetchCriteria.fields[" + index + "]",
                        isNew: false,
                      });
                      setShowDSBModal(true);
                    }}
                    variant="link"
                  >
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </Button>
                </Card.Header>
                <Accordion.Collapse
                  eventKey={activeMultiKey[index]}
                  in={activeMultiKey[index]}
                >
                  <Card.Body className={"px-4 pb-4 background-primary"}>
                    {row?.OR?.map((innerRow, innerIndex) => (
                      <CustomAccordion
                        id={"or-operations-inner-collapse" + innerIndex}
                        title={innerRow?.label ?? ""}
                        defaultActiveKey={false}
                        showDragIcon={false}
                        showEditIcon={false}
                        showRemoveIcon={false}
                      >
                        <div className="p-3">
                          {/* OR Operation Object Structure Start From Here */}

                          <div className="row mb-3">
                            <fieldset disabled className="col-3">
                              {/* DS A Field Name DropDown For DSB Fetch Criteria */}
                              <div className="workflowModalTitle">
                                DSA Field Name
                              </div>
                              <div title={innerRow?.ds_a_field_name}>
                                <Select
                                  options={dsaFields}
                                  name={"ds A Field Name"}
                                  value={dsaFields?.find(
                                    (field) =>
                                      field.value === innerRow?.ds_a_field_name
                                  )}
                                  components={{ DropdownIndicator: null }}
                                />
                              </div>
                            </fieldset>

                            <fieldset disabled className="col-3">
                              {/* Operator DropDown For DSB Fetch Criteria  */}
                              <div className="workflowModalTitle">Operator</div>
                              <div
                                title={
                                  operatorOptions?.rightCriteriaOperators?.find(
                                    (operator) =>
                                      operator.value === innerRow?.operator
                                  )?.label
                                }
                              >
                                <Select
                                  options={
                                    operatorOptions?.rightCriteriaOperators
                                  }
                                  name={"Operator"}
                                  value={operatorOptions?.rightCriteriaOperators?.find(
                                    (operator) =>
                                      operator.value === innerRow?.operator
                                  )}
                                  components={{ DropdownIndicator: null }}
                                />
                              </div>
                            </fieldset>
                            <fieldset disabled className="col-3">
                              {/* DS B Field Name DropDown For DSB Fetch Criteria  */}
                              <div className="workflowModalTitle">
                                DSB Field Name
                              </div>
                              <div title={innerRow?.ds_b_field_name}>
                                <Select
                                  options={dsbFields}
                                  name={"ds B Field Name"}
                                  value={dsbFields?.find(
                                    (field) =>
                                      field.value === innerRow?.ds_b_field_name
                                  )}
                                  components={{ DropdownIndicator: null }}
                                />
                              </div>
                            </fieldset>
                            {/* Show Tolerance Days when operator is BTW */}
                            {innerRow?.operator === "BTW" && (
                              <fieldset disabled className="col-1">
                                <div className="workflowModalTitle">
                                  Min Tol
                                </div>
                                <Field
                                  className="form-control rs-input highlight"
                                  type="number"
                                  name={`toFetchCriteria.fields[${index}].OR[${innerIndex}].tolerance_days[0]`}
                                  onChange={(e) => {
                                    if (Number(e.target.value) > 0) {
                                      return;
                                    }
                                    setFieldValue(
                                      `toFetchCriteria.fields[${index}].OR[${innerIndex}].tolerance_days[0]`,
                                      e?.target?.value
                                    );
                                  }}
                                  style={{ width: "70px" }}
                                />
                              </fieldset>
                            )}
                            {innerRow?.operator === "BTW" && (
                              <fieldset disabled className="col-1">
                                <div className="workflowModalTitle">
                                  Max Tol
                                </div>
                                <Field
                                  className="form-control rs-input highlight"
                                  type="number"
                                  name={`toFetchCriteria.fields[${index}].OR[${innerIndex}].tolerance_days[1]`}
                                  onChange={(e) => {
                                    if (Number(e.target.value) < 0) {
                                      return;
                                    }
                                    setFieldValue(
                                      `toFetchCriteria.fields[${index}].OR[${innerIndex}].tolerance_days[1]`,
                                      e?.target?.value
                                    );
                                  }}
                                  style={{ width: "70px" }}
                                />
                              </fieldset>
                            )}
                            {/* Tolerance Days Ends Here */}

                            {/* Show and Hide Icon */}
                            <div className="col-1 d-flex align-items-center justify-content-around pt-3">
                              <i
                                id={"DSB_OR_Toggle" + index + innerIndex}
                                onClick={() => {
                                  setOpen((prev) => {
                                    return {
                                      ...prev,
                                      [index]: {
                                        ...prev?.[index],
                                        [innerIndex]:
                                          !prev?.[index]?.[innerIndex],
                                      },
                                    };
                                  });
                                }}
                                style={{
                                  color: "#118ab2",
                                  cursor: "pointer",
                                }}
                                className={
                                  "fa" +
                                  (open?.[index]?.[innerIndex]
                                    ? " fa-chevron-up"
                                    : " fa-chevron-down")
                                }
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                          <Collapse
                            className="mt-3"
                            id={
                              "modalAdvancedOptionsDSB/ORUpdate" +
                              index +
                              innerIndex
                            }
                            in={open?.[index]?.[innerIndex] || false}
                          >
                            <fieldset disabled className="row">
                              <div className="col-3">
                                {/* Label Text Field For DSB Fetch Criteria  */}
                                <div className="workflowModalTitle">Label</div>
                                <Field
                                  title={innerRow?.label}
                                  className="form-control rs-input highlight"
                                  type="text"
                                  placeholder="Label"
                                  name={`toFetchCriteria.fields[${index}].OR[${innerIndex}].label`}
                                  onChange={(e) =>
                                    setFieldValue(
                                      `toFetchCriteria.fields[${index}].OR[${innerIndex}].label`,
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              {innerRow?.hasOwnProperty("ds_b_field_value") && (
                                <div className="col-3">
                                  {/* DS B Field Value Text Field For DSB Fetch Criteria */}
                                  <div className="workflowModalTitle">
                                    DSB Field Value
                                  </div>
                                  <Field
                                    title={innerRow?.ds_b_field_value}
                                    className="form-control rs-input highlight"
                                    type="text"
                                    placeholder="DSB Field Value"
                                    name={`toFetchCriteria.fields[${index}].OR[${innerIndex}].ds_b_field_value`}
                                    onChange={(e) =>
                                      setFieldValue(
                                        `toFetchCriteria.fields[${index}].OR[${innerIndex}].ds_b_field_value`,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              )}
                              <div className="col-3">
                                {/* Provide Field Value Switch */}
                                <div className="workflowModalTitle">
                                  Provide Field Value
                                </div>
                                <div className="form-check py-2">
                                  <FormCheck
                                    type="switch"
                                    name="provideFieldValue"
                                    style={{ zIndex: 0 }}
                                    checked={innerRow?.hasOwnProperty(
                                      "ds_b_field_value"
                                    )}
                                    onChange={(e) => {
                                      // If checked then show ds_b_field_value
                                      if (e.target.checked) {
                                        setFieldValue(
                                          `toFetchCriteria.fields[${index}].OR[${innerIndex}]`,
                                          {
                                            ...values.toFetchCriteria.fields[
                                              index
                                            ].OR[innerIndex],
                                            ds_b_field_value: "",
                                          }
                                        );
                                      } else {
                                        //remove  ds_b_field_value from object
                                        const {
                                          ds_b_field_value,
                                          ...updatedField
                                        } =
                                          values.toFetchCriteria.fields[index]
                                            .OR[innerIndex];
                                        // Update the state with the new object
                                        setFieldValue(
                                          `toFetchCriteria.fields[${index}].OR[${innerIndex}]`,
                                          updatedField
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              {/* Check Account Relationship */}
                              {innerRow?.hasOwnProperty(
                                "check_relationship"
                              ) && (
                                <div className="col-3">
                                  <div className="workflowModalTitle">
                                    Check Account Relationship
                                  </div>
                                  <div className="form-check py-2">
                                    <FormCheck
                                      type="switch"
                                      name="check_relationship"
                                      style={{ zIndex: 0 }}
                                      checked={innerRow.check_relationship}
                                      onChange={(e) =>
                                        setFieldValue(
                                          `toFetchCriteria.fields[${index}].OR[${innerIndex}].check_relationship`,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </fieldset>
                          </Collapse>
                        </div>
                      </CustomAccordion>
                    ))}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          )
      )}
      {values?.matching === "1:MAG" && (
        <>
          <div className="row mt-4">
            <div className="col-4 align-content-center">
              <div className="title">Aggregates</div>
            </div>
            <div className="col-8 d-flex justify-content-end">
              <ButtonTransparent
                id="addAggregate"
                title={"Add Aggregate"}
                icon={AddIcon}
                onClick={() => {
                  setEditAggregateObject({
                    path: `toFetchCriteria.aggregates[${
                      values?.toFetchCriteria?.aggregates?.length > 0
                        ? values?.toFetchCriteria?.aggregates?.length
                        : 0
                    }]`,
                    isNew: true,
                  });
                  setAggregateObject({
                    label: "",
                    ds_b_field_name: "",
                    operator: "",
                    resultset_col_name: "",
                  });
                  setShowAggregateModal(true);
                }}
                border
              />
            </div>
          </div>
          {values?.toFetchCriteria?.aggregates?.length > 0 ? (
            values?.toFetchCriteria?.aggregates?.map((row, index) => (
              <CustomAccordion
                id={"aggregate"}
                index={index}
                title={row?.label ?? ""}
                onRemove={() => {
                  // remove current object
                  setShowAggregateDeleteModal(true);
                  setAggregateDeleteIndex(index);
                }}
                onEdit={() => {
                  setAggregateObject(row);
                  setEditAggregateObject({
                    path: "toFetchCriteria.aggregates[" + index + "]",
                    isNew: false,
                  });
                  setShowAggregateModal(true);
                }}
                defaultActiveKey={false}
                showDragIcon={false}
                showEditIcon={true}
              >
                <fieldset disabled className="p-3 my-3">
                  <div className="row">
                    <div className="col-3">
                      {/* Label Text Field */}
                      <div className="workflowModalTitle">Label</div>
                      <Field
                        title={row?.label}
                        className="form-control rs-input highlight"
                        type="text"
                        placeholder="Label"
                        value={row?.label}
                      />
                    </div>
                    <div className="col-3">
                      {/* DS B Field Name DropDown */}
                      <div className="workflowModalTitle">DSB Field Name</div>
                      <div title={row?.ds_b_field_name}>
                        <Select
                          options={dsbFields}
                          name={"ds B Field Name"}
                          value={dsbFields?.find(
                            (field) => field.value === row?.ds_b_field_name
                          )}
                          components={{ DropdownIndicator: null }}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      {/* Operator DropDown */}
                      <div className="workflowModalTitle">Operator</div>
                      <div
                        title={
                          operatorOptions?.rightCriteriaAggregateOperators?.find(
                            (operator) => operator.value == row?.operator
                          )?.label
                        }
                      >
                        <Select
                          options={
                            operatorOptions?.rightCriteriaAggregateOperators
                          }
                          name={"Operator"}
                          value={operatorOptions?.rightCriteriaAggregateOperators?.find(
                            (operator) => operator.value == row?.operator
                          )}
                          components={{ DropdownIndicator: null }}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      {/* Result Set Col Text Field */}
                      <div className="workflowModalTitle">
                        Result Set Col Name
                      </div>
                      <Field
                        title={row?.resultset_col_name}
                        className="form-control rs-input highlight"
                        type="text"
                        placeholder="Result Set Col Name"
                        value={row?.resultset_col_name}
                      />
                    </div>
                  </div>
                </fieldset>
              </CustomAccordion>
            ))
          ) : (
            <div className="text-center mt-3">No Aggregates Found</div>
          )}
        </>
      )}
      {showDSBModal ? (
        <DSBModal
          show={showDSBModal}
          editObject={editDSBObject}
          onHide={() => {
            setShowDSBModal(false);
            setDsbObject(null);
            setEditDSBObject({ isNew: false, path: null });
          }}
          handleSubmit={(path, value) => {
            if (value?.hasOwnProperty("OR")) {
              setFieldValue(path, value);
            } else {
              if (editDSBObject?.isNew) {
                value?.forEach((item, index) => {
                  setFieldValue(
                    `toFetchCriteria.fields[${
                      values?.toFetchCriteria?.fields?.length > 0
                        ? values?.toFetchCriteria?.fields?.length + index
                        : index
                    }]`,

                    item
                  );
                });
              } else {
                setFieldValue(path, value[0]);
                value?.splice(0, 1);
                value?.forEach((item, index) => {
                  setFieldValue(
                    `toFetchCriteria.fields[${
                      values?.toFetchCriteria?.fields?.length > 0
                        ? values?.toFetchCriteria?.fields?.length + index
                        : index
                    }]`,

                    item
                  );
                });
              }
            }
          }}
          dsaFields={dsaFields}
          dsbFields={dsbFields}
          values={dsbObject}
          operatorOptions={operatorOptions}
        />
      ) : null}
      <ConfirmationModal
        isShow={showDSBDeleteModal}
        title={`Delete DSB Fetch Criteria Field`}
        message={`Are you sure you want to delete this DSB Fetch Criteria Field?`}
        hideModal={() => {
          setShowDSBDeleteModal(false);
        }}
        confirmAction={() => {
          values.toFetchCriteria.fields.splice(dsbDeleteIndex, 1);
          setFieldValue(
            "toFetchCriteria.fields",
            values.toFetchCriteria.fields
          );
          setDSBDeleteIndex(null);
          setShowDSBDeleteModal(false);
        }}
      />
      {showAggregateModal ? (
        <AggregateModal
          show={showAggregateModal}
          editObject={editAggregateObject}
          onHide={() => {
            setShowAggregateModal(false);
            setAggregateObject(null);
            setEditAggregateObject({ isNew: false, path: null });
          }}
          handleSubmit={(path, values) => setFieldValue(path, values)}
          dsbFields={dsbFields}
          values={aggregateObject}
          aggregateFields={aggregateFields}
          setAggregateFields={setAggregateFields}
          operatorOptions={operatorOptions}
        />
      ) : null}
      <ConfirmationModal
        isShow={showAggregateDeleteModal}
        title={`Delete DSB Fetch Criteria Aggregate`}
        message={`Are you sure you want to delete this DSA Fetch Criteria Aggregate?`}
        hideModal={() => {
          setShowAggregateDeleteModal(false);
        }}
        confirmAction={() => {
          setAggregateFields((prevState) =>
            prevState?.filter(
              (obj, i) =>
                obj.value !==
                "data." +
                  values?.toFetchCriteria?.aggregates[aggregateDeleteIndex]
                    ?.resultset_col_name
            )
          );
          // remove current object
          values.toFetchCriteria.aggregates.splice(aggregateDeleteIndex, 1);
          setFieldValue(
            "toFetchCriteria.aggregates",
            values.toFetchCriteria.aggregates
          );
          setShowAggregateDeleteModal(false);
          setAggregateDeleteIndex(null);
        }}
      />
    </div>
  );
};
