import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./assets/styles/GlobalStyles";
import { lightTheme, darkTheme, DibTheme } from "./assets/styles/Theme";
// import ThemeToggle from "./components/ThemeToggle/ThemeToggle";
import { useDarkMode } from "./components/UseDarkMode/UseDarkMode";
import { DefaultLayout } from "./containers";
import Login from "./views/Login/Login";
import NewResetPassword from "./components/ChangePassword/NewResetPassword";
import ResetPassword from "./views/ResetPassword/ResetPassword";
import PrivateRoute from "./navigation/PrivateRoute";
import history from "./utils/history";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "./navigation/ProvideAuth";
import useTabActive from "./hooks/useTabActive";
import TwoFactorAuth from "./views/TwoFactorAuth/TwoFactorAuth";
import VerifyTwoFactorAuth from "./views/TwoFactorAuth/VerifyTwoFactorAuth";
function App() {
  const isLoggedIn = useAuth();
  const isTabActive = useTabActive();
  const [theme, themeToggler] = useDarkMode();
  let themeMode =
    theme === "light" ? lightTheme : theme === "dib" ? DibTheme : darkTheme;

  if (
    window.location.pathname === "/login" ||
    window.location.pathname === "/forgot-password"
  ) {
    themeMode = process.env.REACT_APP_THEME === "dib" ? DibTheme : lightTheme;
  }

  useEffect(() => {
    if (isTabActive && !isLoggedIn && !window.location.pathname === "/login") {
      history.push("/login");
    }
  }, [isLoggedIn, isTabActive]);

  return (
    <>
      <Router history={history}>
        <ThemeProvider theme={themeMode}>
          <div>
            <GlobalStyles />
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/reset-password">
                <NewResetPassword />
              </Route>
              <Route path="/forgot-password">
                <ResetPassword />
              </Route>
              <Route path="/2fa-setup">
                <TwoFactorAuth />
              </Route>
              <Route path="/2fa-verify">
                <VerifyTwoFactorAuth/>
                </Route>
              <Route path="*">
                <PrivateRoute
                  component={DefaultLayout}
                  theme={theme}
                  toggleTheme={themeToggler}
                />
                <ToastContainer />
              </Route>
            </Switch>
          </div>
        </ThemeProvider>
      </Router>
    </>
  );
}

export default App;
