// import React, { useState, useEffect } from "react";
// import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import moment from "moment";
// import * as Yup from "yup";
// import "react-big-calendar/lib/css/react-big-calendar.css";
// import axiosInstance from "../../../utils/axiosInstance";
// import FlexibleHolidays from "./FlexibleHolidays";
// import ButtonTransparent from "../../../components/Buttons/ButtonTransparent";
// import AddIcon from "../../../assets/images/add-icon.png";
// import Modal from "react-bootstrap/Modal";
// import ButtonBasic from "../../../components/Buttons/ButtonBasic";

// const localizer = momentLocalizer(moment);

// const ReconCalendar = () => {
//   const currentYear = new Date().getFullYear();
//   const [events, setEvents] = useState([]);
//   const [showModal, setShowModal] = useState(false);

//   useEffect(() => {
//     fetchYearlyEvents(currentYear);
//   }, [currentYear]);

//   const fetchYearlyEvents = async (year) => {
//     try {
//       const response = await axiosInstance.get(`/calendar/year?year=${year}`);
//       if (response?.message?.status === "200") {
//         const formattedEvents = response?.data?.map((event) => ({
//           id: event.id,
//           title: event.description,
//           start: new Date(event.date),
//           end: new Date(event.date),
//           allDay: true,
//         }));
//         setEvents(formattedEvents);
//       } else {
//         console.error(
//           "Failed to fetch events:",
//           response?.data?.message?.description
//         );
//       }
//     } catch (error) {
//       console.error("Error fetching events:", error);
//     }
//   };

//   const handleAddHoliday = async (values, { setSubmitting, resetForm }) => {
//     try {
//       const payload = {
//         data: {
//           id: 0, // Server will handle the ID
//           date: values.date,
//           description: values.description,
//         },
//       };

//       const response = await axiosInstance.post(`/calendar`, payload);
//       if (response?.message?.status === "200") {
//         alert("Holiday added successfully!");
//         setShowModal(false);
//         resetForm();
//         fetchYearlyEvents(currentYear);
//       } else {
//         console.error("Failed to add holiday.");
//       }
//     } catch (error) {
//       console.error("Error adding holiday:", error);
//     } finally {
//       setSubmitting(false);
//     }
//   };

//   const handleDeleteEvent = async (eventId) => {
//     if (window.confirm("Are you sure you want to delete this holiday?")) {
//       try {
//         const response = await axiosInstance.delete(`/calendar/${eventId}`);
//         if (response?.message?.status === "200") {
//           alert("Holiday deleted successfully!");
//           fetchYearlyEvents(currentYear);
//         } else {
//           console.error("Failed to delete holiday.");
//         }
//       } catch (error) {
//         console.error("Error deleting holiday:", error);
//       }
//     }
//   };

//   const addFlexibleHolidays = (selectedDays) => {
//     const dayIndices = {
//       Sunday: 0,
//       Monday: 1,
//       Tuesday: 2,
//       Wednesday: 3,
//       Thursday: 4,
//       Friday: 5,
//       Saturday: 6,
//     };

//     const flexibleEvents = [];
//     const startDate = new Date(currentYear, 0, 1); // Jan 1st
//     const endDate = new Date(currentYear, 11, 31); // Dec 31st

//     for (
//       let date = new Date(startDate);
//       date <= endDate;
//       date.setDate(date.getDate() + 1)
//     ) {
//       if (selectedDays.includes(Object.keys(dayIndices)[date.getDay()])) {
//         flexibleEvents.push({
//           id: `${date.toISOString()}-${Math.random()}`, // Temporary unique ID
//           title: "Flexible Holiday",
//           start: new Date(date),
//           end: new Date(date),
//           allDay: true,
//         });
//       }
//     }

//     setEvents((prevEvents) => [...prevEvents, ...flexibleEvents]);
//   };

//   // Form validation schema using Yup
//   const validationSchema = Yup.object().shape({
//     date: Yup.date()
//       .required("Date is required")
//       .min(new Date(currentYear, 0, 1), `Date must be within ${currentYear}`)
//       .max(new Date(currentYear, 11, 31), `Date must be within ${currentYear}`),
//     description: Yup.string()
//       .required("Description is required")
//       .max(50, "Description cannot exceed 50 characters"),
//   });

//   return (
//     <div className="calendar-container pb-5">
//       <div className="row page-header d-flex justify-content-between">
//         <div className="col-sm-12 col-lg-12 page-header d-flex justify-content-between m-0">
//           <div className="title">{`Yearly Holidays Calendar - ${currentYear}`}</div>
//           <div>
//             <ButtonTransparent
//               id="addHoliday"
//               title={"Add Holiday"}
//               icon={AddIcon}
//               onClick={() => setShowModal(true)}
//             />
//           </div>
//         </div>
//       </div>

//       {/* Modal for Adding a New Holiday */}
//       <Modal
//         backdrop={true}
//         size="md"
//         show={showModal}
//         onHide={() => setShowModal(false)}
//         aria-labelledby="example-modal-sizes-title-lg"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Add Holiday</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Formik
//             initialValues={{ date: "", description: "" }}
//             validationSchema={validationSchema}
//             onSubmit={handleAddHoliday}
//           >
//             {({ isSubmitting }) => (
//               <Form>
//                 <div className="form-group">
//                   <label htmlFor="date">Date:</label>
//                   <Field type="date" name="date" className="form-control" />
//                   <ErrorMessage
//                     name="date"
//                     component="div"
//                     className="text-danger"
//                   />
//                 </div>
//                 <div className="form-group">
//                   <label htmlFor="description">Description:</label>
//                   <Field
//                     type="text"
//                     name="description"
//                     className="form-control"
//                     placeholder="Holiday Description"
//                   />
//                   <ErrorMessage
//                     name="description"
//                     component="div"
//                     className="text-danger"
//                   />
//                 </div>
//                 <div className="d-flex justify-content-end">
//                   <button
//                     type="submit"
//                     className={"btn btn-info btn-md mr-2"}
//                     disabled={isSubmitting}
//                   >
//                     {isSubmitting ? "Adding..." : "Add Holiday"}
//                   </button>
//                   <ButtonBasic
//                     title="Cancel"
//                     onClick={() => {
//                       setShowModal(false);
//                     }}
//                   />
//                 </div>
//               </Form>
//             )}
//           </Formik>
//         </Modal.Body>
//       </Modal>

//       <FlexibleHolidays onAddFlexibleHolidays={addFlexibleHolidays} />
//       <BigCalendar
//         localizer={localizer}
//         events={events}
//         startAccessor="start"
//         endAccessor="end"
//         style={{ height: 600 }}
//         views={["month"]}
//         defaultView="month"
//         popup
//         onSelectEvent={(event) => handleDeleteEvent(event.id)}
//       />
//     </div>
//   );
// };

// export default ReconCalendar;

import React, { useState, useEffect, useRef } from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import axiosInstance from "../../../utils/axiosInstance";
import ButtonTransparent from "../../../components/Buttons/ButtonTransparent";
import AddIcon from "../../../assets/images/add-icon.png";
import Modal from "react-bootstrap/Modal"; // Assuming you're using react-bootstrap
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import { Alert, OverlayTrigger, Tooltip } from "react-bootstrap";

const localizer = momentLocalizer(moment);

const ReconCalendar = () => {
  const inputRef = useRef(null);
  const currentYear = new Date().getFullYear();
  const [events, setEvents] = useState([]);
  const [eventId, setEventId] = useState(null);
  const [recurringHolidays, setRecurringHolidays] = useState([]);
  const [recurringWeekday, setRecurringWeekday] = useState(null);
  const [showRecurringDeleteModal, setShowRecurringDeleteModal] =
    useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("add"); // 'add' for Add Holiday, 'flexible' for Recurring Holiday
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  useEffect(() => {
    fetchYearlyEvents(currentYear);
  }, [currentYear]);

  const fetchYearlyEvents = async (year) => {
    try {
      const response = await axiosInstance.get(`/calendar/year?year=${year}`);
      if (response?.message?.status === "200") {
        const formattedEvents = response?.data?.holidays?.map((event) => ({
          id: event.id,
          title: event.description,
          start: new Date(event.date),
          end: new Date(event.date),
          allDay: true,
        }));
        setRecurringHolidays(response?.data?.recurringDays || []);
        setEvents(formattedEvents || []);
      } else {
        console.error(
          "Failed to fetch events:",
          response?.data?.message?.description
        );
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const handleAddHoliday = async (values, { setSubmitting, resetForm }) => {
    try {
      const payload = {
        data: {
          id: 0, // Server will handle the ID
          date: values.date,
          description: values.description,
        },
      };

      const response = await axiosInstance.post(`/calendar`, payload);
      if (response?.message?.status === "200") {
        setShowModal(false);
        resetForm();
        fetchYearlyEvents(currentYear);
      } else {
        console.error("Failed to add holiday.");
      }
    } catch (error) {
      console.error("Error adding holiday:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleAddFlexibleHolidays = async (
    values,
    { setSubmitting, resetForm }
  ) => {
    const selectedDays = values.days; // Array of selected days
    const payload = {
      data: {
        recurringDays: selectedDays?.map((day) => day?.toUpperCase()),
        description: "Recurring Holidays",
      },
    };

    try {
      const response = await axiosInstance.post(`/calendar/add-all`, payload);
      if (response?.message?.status === "200") {
        setShowModal(false);
        resetForm();
        fetchYearlyEvents(currentYear);
      } else {
        console.error("Failed to add recurring holidays.");
      }
    } catch (error) {
      console.error("Error adding recurring holidays:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteEvent = async (eventId) => {
    try {
      const response = await axiosInstance.delete(`/calendar/${eventId}`);
      if (response?.message?.status === "200") {
        fetchYearlyEvents(currentYear);
        setShowConfirmationModal(false);
      } else {
        console.error("Failed to delete holiday.");
      }
    } catch (error) {
      console.error("Error deleting holiday:", error);
    }
  };

  const handleDeleteRecurringEvent = async (recurringDays) => {
    const payload = {
      data: {
        recurringDays,
      },
    };

    try {
      const response = await axiosInstance.delete(`/calendar`, {
        data: payload,
      });
      if (response?.message?.status === "200") {
        setShowModal(false);
        setShowRecurringDeleteModal(false);
        fetchYearlyEvents(currentYear);
      } else {
        console.error("Failed to delete recurring holidays.");
      }
    } catch (error) {
      console.error("Error deleting recurring holidays:", error);
    }
  };

  const validationSchemaAdd = Yup.object().shape({
    date: Yup.date()
      .required("Date is required")
      .min(new Date(currentYear, 0, 1), `Date must be within ${currentYear}`)
      .max(new Date(currentYear, 11, 31), `Date must be within ${currentYear}`),
    description: Yup.string()
      .required("Description is required")
      .typeError("Description is must be a string"),
  });

  const validationSchemaFlexible = Yup.object().shape({
    days: Yup.array()
      .of(
        Yup.string().oneOf([
          "SUNDAY",
          "MONDAY",
          "TUESDAY",
          "WEDNESDAY",
          "THURSDAY",
          "FRIDAY",
          "SATURDAY",
        ])
      )
      .min(1, "Select at least one day")
      .required("Select at least one day"),
  });

  const toggleCalendar = (e) => {
    e.preventDefault();
    const input = inputRef.current;
    if (input) {
      if (isCalendarOpen) {
        input.blur(); // Close the calendar
      } else {
        input.showPicker(); // Open the calendar
      }
      setIsCalendarOpen(!isCalendarOpen);
    }
  };

  return (
    <div className="calendar-container pb-5">
      <div className="row page-header d-flex justify-content-between">
        <div className="col-sm-12 col-lg-12 page-header d-flex justify-content-between m-0">
          <div className="title">{`Recon Calendar - ${currentYear}`}</div>
          <div>
            <ButtonTransparent
              id="addHoliday"
              title={"Add Holiday"}
              icon={AddIcon}
              onClick={() => {
                setModalMode("add");
                setShowModal(true);
              }}
            />
            <ButtonTransparent
              id="flexibleHoliday"
              title={"Recurring Holidays"}
              icon={AddIcon}
              onClick={() => {
                setModalMode("flexible");
                setShowModal(true);
              }}
            />
          </div>
        </div>
      </div>

      <Modal
        backdrop={true}
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {modalMode === "add" ? "Add Holiday" : "Recurring Holiday"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalMode === "add" ? (
            <Formik
              initialValues={{ date: "", description: "" }}
              validationSchema={validationSchemaAdd}
              onSubmit={handleAddHoliday}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="form-group">
                    <label htmlFor="date">Date:</label>
                    <Field
                      innerRef={inputRef}
                      type="date"
                      name="date"
                      className="form-control cursor-pointer"
                      min={new Date().toISOString().split("T")[0]}
                      onKeyDown={(e) => e.preventDefault()}
                      onClick={toggleCalendar}
                    />
                    <ErrorMessage
                      name="date"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="description">Description:</label>
                    <Field
                      type="text"
                      name="description"
                      className="form-control"
                      placeholder="Holiday Description"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <ButtonBasic
                      title="Cancel"
                      onClick={() => {
                        setShowModal(false);
                      }}
                    />
                    <button
                      type="submit"
                      className={"btn btn-info btn-md mr-2"}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Adding..." : "Add Holiday"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <Formik
              initialValues={{
                days: recurringHolidays.length > 0 ? recurringHolidays : [],
              }}
              validationSchema={validationSchemaFlexible}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                if (recurringHolidays.length > 0) {
                  const oldDaysSet = new Set(
                    recurringHolidays.map((day) => day.toUpperCase())
                  );
                  const newDaysSet = new Set(
                    values?.days.map((day) => day.toUpperCase())
                  );

                  const daysToRemove = [...oldDaysSet].filter(
                    (day) => !newDaysSet.has(day)
                  );
                  const daysToAdd = [...newDaysSet].filter(
                    (day) => !oldDaysSet.has(day)
                  );
                  if (daysToRemove.length === 0 && daysToAdd.length === 0) {
                    setSubmitting(false);
                    resetForm();
                    setShowModal(false);
                  }
                  if (daysToRemove.length > 0) {
                    handleDeleteRecurringEvent(daysToRemove);
                  }

                  if (daysToAdd.length > 0) {
                    handleAddFlexibleHolidays(
                      { days: daysToAdd },
                      {
                        setSubmitting,
                        resetForm,
                      }
                    );
                  }
                } else if (recurringHolidays.length === 0) {
                  handleAddFlexibleHolidays(values, {
                    setSubmitting,
                    resetForm,
                  });
                }
              }}
            >
              {({ isSubmitting, values }) => (
                <Form>
                  <div className="form-group">
                    <label>Select Days for Recurring Holiday:</label>
                    <div className="form-check">
                      {[
                        "SUNDAY",
                        "MONDAY",
                        "TUESDAY",
                        "WEDNESDAY",
                        "THURSDAY",
                        "FRIDAY",
                        "SATURDAY",
                      ].map((day) => (
                        <div key={day}>
                          <Field
                            type="checkbox"
                            name="days"
                            value={day}
                            className="form-check-input"
                            id={`day-${day}`}
                            checked={values.days.includes(day)}
                          />
                          <label
                            htmlFor={`day-${day}`}
                            className="form-check-label"
                          >
                            {day}
                          </label>
                        </div>
                      ))}
                    </div>
                    <ErrorMessage
                      name="days"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <Alert className="m-0" variant="warning">
                        {`This Recurrence Applies to the Whole Year`}
                      </Alert>
                    </div>
                    <div className="d-flex">
                      <ButtonBasic
                        title="Cancel"
                        onClick={() => {
                          setShowModal(false);
                        }}
                      />
                      <ButtonBasic
                        type="submit"
                        className={"btn btn-info btn-md mr-2"}
                        disabled={isSubmitting}
                        title={
                          isSubmitting
                            ? recurringHolidays.length > 0
                              ? "Updating..."
                              : "Adding..."
                            : recurringHolidays.length > 0
                            ? "Update Recurring Holiday"
                            : "Add Recurring Holiday"
                        }
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Body>
      </Modal>
      <ConfirmationModal
        isShow={showConfirmationModal}
        hideModal={() => {
          setEventId(null);
          setShowConfirmationModal(false);
        }}
        title="Delete Holiday"
        message="Are you sure you want to delete this holiday?"
        confirmAction={() => {
          handleDeleteEvent(eventId);
        }}
      />
      <ConfirmationModal
        isShow={showRecurringDeleteModal}
        hideModal={() => {
          setRecurringWeekday(null);
          setShowRecurringDeleteModal(false);
        }}
        title="Delete Recurring Holiday"
        message={`This action will delete all ${recurringWeekday} recurring holidays. Are you sure you want to delete ${recurringWeekday} recurring holiday?`}
        confirmAction={() => {
          handleDeleteRecurringEvent([recurringWeekday]);
        }}
      />
      <BigCalendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 600 }}
        views={["month"]}
        defaultView="month"
        components={{
          event: ({ event }) => (
            <OverlayTrigger
              overlay={
                new Date(event?.start) > new Date() ||
                new Date(event.start).toDateString() ===
                  new Date().toDateString() ? (
                  <Tooltip>{event?.title}</Tooltip>
                ) : (
                  <Tooltip>You cannot delete this holiday.</Tooltip>
                )
              }
              placement="top"
            >
              <span>{event.title}</span>
            </OverlayTrigger>
          ),
        }}
        tooltipAccessor={() => ""}
        popup
        onSelectEvent={(event) => {
          const today = new Date();
          const eventDate = new Date(event.start);
          const dayOfWeek = eventDate
            ?.toLocaleString("en-US", { weekday: "long" })
            ?.toUpperCase();
          const isFutureDate =
            eventDate > today ||
            (eventDate.getFullYear() === today.getFullYear() &&
              eventDate.getMonth() === today.getMonth() &&
              eventDate.getDate() === today.getDate());
          if (isFutureDate && recurringHolidays?.includes(dayOfWeek)) {
            setRecurringWeekday(dayOfWeek);
            setShowRecurringDeleteModal(true);
          } else if (isFutureDate) {
            setEventId(event.id);
            setShowConfirmationModal(true);
          }
        }}
      />
    </div>
  );
};

export default ReconCalendar;
