import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import axiosInstance from "../../../utils/axiosInstance";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import ButtonTransparent from "../../../components/Buttons/ButtonTransparent";
import AddIcon from "../../../assets/images/add-icon.png";
import EditIcon from "../../../assets/images/edit.png";
import TrashIcon from "../../../assets/images/trash.png";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import Toast from "../../../components/Toast/Toast";
import { Modal } from "react-bootstrap";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import ViewModal from "./ViewModal";
import { useDateTime } from "../../../components/Helper/DateTime";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

const InstitutionSchema = Yup.object().shape({
  institutionId: Yup.number()
    .required("Required*")
    .typeError("Value must be numbers"),
  institutionName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required*"),
  userEmailDomain: Yup.string()
    .matches(/^@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid domain format")
    .optional()
    .nullable(),
  baseCurrencyCode: Yup.string().required("Required*"),
  bicCode: Yup.string().required("Required*"),
  bin: Yup.string().required("Required*"),
});
const obj = {
  institutionId: "",
  institutionName: "",
  userEmailDomain: null,
  baseCurrencyCode: "",
  bicCode: "",
  bin: "",
};
const InstitutionManagement = () => {
  const { setRefresh } = useDateTime();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [institution, setInstitution] = useState(obj);

  const getInstitutions = () => {
    setIsLoading(true);
    axiosInstance
      .get("/institutions")
      .then((res) => {
        setData(res.data.institutions);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const HandleDeleteInstitution = () => {
    setIsLoading(true);
    axiosInstance
      .delete(`/institutions/${id}`)
      .then((res) => {
        // eslint-disable-next-line
        if (res.message.status == 200) {
          setData(data.filter((institution) => institution.id !== id));
          setIsDeleteModal(false);
          setIsLoading(false);
          Toast("Record deleted!", "success");
        } else {
          setIsLoading(false);
          setIsDeleteModal(false);
          Toast(res.message.description, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setIsDeleteModal(false);
      });
  };
  const handleEdit = (values) => {
    setIsLoading(true);
    axiosInstance
      .put(`/institutions`, { data: { institutions: [values] } })
      .then((res) => {
        // eslint-disable-next-line
        if (res.message.status == 200) {
          setIsEdit(false);
          setIsLoading(false);
          setShowModal(false);
          const newData = [...data];
          newData[newData.findIndex((institution) => institution.id === id)] =
            res.data.institutions[0];
          setData([...newData]);
          setInstitution(obj);
          setId("");
          // getInstitutions();
          Toast("Record updated!", "success");
        } else {
          Toast(res.message.description, "error");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        Toast(err.response.data.message.description, "error");
        setIsLoading(false);
      });
  };
  const handleAdd = (values) => {
    setIsLoading(true);
    axiosInstance
      .post("/institutions", { data: { institutions: [values] } })
      .then((res) => {
        // eslint-disable-next-line
        if (res.message.status == 200) {
          setIsLoading(false);
          setShowModal(false);
          getInstitutions();
          setData([...data, res.data.institutions[0]]);
          setInstitution(obj);
          setId("");
          Toast("Record added!", "success");
        } else {
          Toast(res.message.description, "error");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        Toast(err.response.data.message.description, "error");
        console.log(err);
        setIsLoading(false);
      });
  };
  const handleSubmit = (values) => {
    isEdit ? handleEdit(values) : handleAdd(values);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInstitution({ ...institution, [name]: value });
  };
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setShowViewModal(true);
      setId(row.id);
    },
  };
  useEffect(() => {
    getInstitutions();
    setRefresh(() => () => {
      getInstitutions();
    });
    return () => {
      setRefresh(() => () => {});
    };
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (isEdit && id) {
      setInstitution(data.find((institution) => institution.id === id));
    }
    // eslint-disable-next-line
  }, [isEdit, id]);
  const columns = [
    {
      dataField: "institutionId",
      text: "Institution Id",
      hidden: true,
    },
    {
      dataField: "institutionName",
      text: "Institution Name",
    },
    {
      dataField: "userEmailDomain",
      text: "User Email Domain",
      formatter: (cell) => cell ? cell : "-",
    },
    {
      dataField: "baseCurrencyCode",
      text: "Base Currency",
    },
    {
      dataField: "bicCode",
      text: "BIC Code",
    },
    {
      dataField: "bin",
      text: "BIN",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row, rowIndex) => {
        return (
          <div>
            <img
              id={"editIconRow" + rowIndex}
              width={24}
              height={24}
              src={EditIcon}
              className={`right-btn mr-2`}
              alt="edit-btn"
            />
            <img
              id={"deleteIconRow" + rowIndex}
              width={24}
              height={24}
              src={TrashIcon}
              className={`right-btn mr-3`}
              alt="dlt-btn"
            />
          </div>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
          if (e.target.alt === "edit-btn") {
            setId(row.id);
            setIsEdit(true);
            setShowModal(true);
          } else if (e.target.alt === "dlt-btn") {
            setIsDeleteModal(true);
            setId(row.id);
          }
        },
      },
    },
  ];
  return (
    <>
      {isLoading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div className="row page-header d-flex justify-content-between">
        <div className="col-sm-12 col-lg-12 page-header d-flex justify-content-between m-0">
          <div className="title">Institution List</div>
          <div>
            <ButtonTransparent
              id="addInstitution"
              onClick={() => {
                setShowModal(true);
                setIsEdit(false);
                setInstitution(obj);
              }}
              title={"Add Institution"}
              icon={AddIcon}
            />
          </div>
        </div>
      </div>
      <div style={{ opacity: !isLoading ? "1" : "0.07" }}>
        <div className="row mt-4">
          <div className="col-12">
            <BootstrapTable
              id="institutionTable"
              columns={columns}
              bootstrap4={true}
              keyField="id"
              bordered={false}
              classes="rs-table table-layout-auto table-custom-style"
              wrapperClasses="overflow-y-auto fancy-scroll"
              data={data}
              rowEvents={rowEvents}
              noDataIndication={"No data found!"}
            />
          </div>
        </div>
        <ConfirmationModal
          isShow={isDeleteModal}
          title={"Delete Institution"}
          message={"Are you sure you want to delete this institution?"}
          hideModal={() => {
            setIsDeleteModal(false);
          }}
          confirmAction={() => HandleDeleteInstitution()}
        />
        {showViewModal && (
          <ViewModal id={id} show={showViewModal} onHide={setShowViewModal} />
        )}

        <Modal
          backdrop={true}
          size="lg"
          show={showModal}
          onHide={() => setShowModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Formik
            initialValues={institution}
            enableReinitialize={true}
            validationSchema={InstitutionSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ errors, touched, handleSubmit, values }) => (
              <Form id="add-user" className="form" onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Institution</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row mt-3">
                    <div className="col-12 mt-3">
                      <div className="d-flex flex-column">
                        <label className="form-label">ID</label>
                        <Field
                          id="institutionId"
                          className="form-control rs-input"
                          type="text"
                          placeholder="Enter Institution Id"
                          name="institutionId"
                        />
                        {errors.institutionId && touched.institutionId ? (
                          <div className="field-error">
                            {errors.institutionId}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="d-flex flex-column">
                        <label className="form-label">Name</label>
                        <Field
                          id="institutionName"
                          className="form-control rs-input"
                          type="text"
                          placeholder="Enter Institution Name"
                          name="institutionName"
                        />
                        {errors.institutionName && touched.institutionName ? (
                          <div className="field-error">
                            {errors.institutionName}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="d-flex flex-column">
                        <label className="form-label">User Email Domain</label>
                        <Field
                          id="userEmailDomain"
                          className="form-control rs-input"
                          type="text"
                          placeholder="Enter User Email Domain (e.g., @domain.com)"
                          name="userEmailDomain"
                        />
                        {errors.userEmailDomain && touched.userEmailDomain ? (
                          <div className="field-error">
                            {errors.userEmailDomain}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="d-flex flex-column">
                        <label className="form-label">
                          Base Currency Code{" "}
                        </label>
                        <Field
                          id="baseCurrencyCode"
                          className="form-control rs-input"
                          type="text"
                          placeholder="Enter base Currency Code"
                          name="baseCurrencyCode"
                        />
                        {errors.baseCurrencyCode && touched.baseCurrencyCode ? (
                          <div className="field-error">
                            {errors.baseCurrencyCode}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="d-flex flex-column">
                        <label className="form-label">BIC Code</label>

                        <Field
                          id="bicCode"
                          className="form-control rs-input"
                          type="text"
                          placeholder="Enter BIC Code"
                          name="bicCode"
                        />
                        {errors.bicCode && touched.bicCode ? (
                          <div className="field-error">{errors.bicCode}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="d-flex flex-column">
                        <label className="form-label">BIN</label>
                        <Field
                          id="bin"
                          className="form-control rs-input"
                          type="text"
                          placeholder="Enter BIN"
                          name="bin"
                        />
                        {errors.bin && touched.bin ? (
                          <div className="field-error">{errors.bin}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex">
                    <button
                      id="submit"
                      type="submit"
                      name="submit"
                      className={"btn btn-info btn-md mr-2"}
                      onClick={handleSubmit}
                    >
                      {isEdit ? "Update" : "Add"}
                    </button>
                    <ButtonBasic
                      title="Cancel"
                      onClick={() => {
                        setShowModal(false);
                        setInstitution(obj);
                        setId("");
                      }}
                    />
                  </div>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </div>
    </>
  );
};

export default InstitutionManagement;
